import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as Document } from 'assets/img/faq/document.svg';
import { ReactComponent as Form } from 'assets/img/faq/form.svg';
import { ReactComponent as Test } from 'assets/img/faq/test.svg';
import { ReactComponent as Subscription } from 'assets/img/faq/subscription.svg';
import { ReactComponent as CreditCard } from 'assets/img/faq/credit-card.svg';

const { innerWidth: width} = window;

const Mobile = () => {
    return width <= 432 || false;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: Mobile()? 18 : 24,
      fontWeight: theme.typography.fontWeightBold,
    },
}));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
          	borderBottom: 0,
        },
        '&:before': {
        	display: 'none',
        },
        '&$expanded': {
        	margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
		backgroundColor: 'white',
        border: '1.5px solid #5900B4',
        borderRadius: 12,
        margin: '1rem 0',
        paddingLeft: 32,
        color: '#5900B4',
      	minHeight: 56,
      	'&$expanded': {
        	minHeight: 56,
      	},
    },
    content: {
      	'&$expanded': {
        	margin: '12px 0',
      	},
    },
    expanded: {
		backgroundColor: '#5900B4',
        color: 'white'
	},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        border: '1.5px solid #5900B4',
        borderTop: 'none',
        marginTop: '-1.5rem',
        borderRadius: '0 0 12px 12px',
        padding: Mobile()? '36px 20px' : '40px 24px',
        color: '#58595B',
        fontSize: Mobile()? 14 : 18
    },
}))(MuiAccordionDetails);

export default function AccordionObligations() {
    const classes = useStyles();
	const IconExpand = () => <ExpandMoreIcon style={{backgroundColor: '#007EFF', borderRadius: 15, color: 'white'}} />;

    return (
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<IconExpand />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>¿Cuáles son mis obligaciones?</Typography>
            </AccordionSummary>
            <AccordionDetails>
				<div className='flex flex-col' >
					<div className='w-full flex flex-wrap items-center' style={{ minHeight: 90}} >
						<div className='flex items-center' style={{ width: '10%'}} >
							<Document className='mx-auto' />
						</div>
						<div className='ml-2' style={{ width: '85%'}}>
							<div className='text-blue font-bold' >
								Llenar su documentación personal
							</div>
							<div>
								Te pedimos información básica personal como comprobante de domicilio, identificación oficial, CURP, Acta de nacimiento.
							</div>
						</div>
					</div>
					<div className='pt-6 flex flex-wrap items-center' style={{ minHeight: 90}} >
						<div className='flex items-center' style={{ width: '10%'}} >
							<Form className='mx-auto' />
						</div>
						<div className='ml-2' style={{ width: '85%'}}>
							<div className='text-blue font-bold' >
								Llenar los formularios de reclamación lo antes posible
							</div>
							<div>Dependemos de su colaboración para tramitar rápidamente los siniestros y los pagos</div>
						</div>
					</div>
					<div className='pt-6 flex flex-wrap items-center' style={{ minHeight: 90}} >
						<div className='flex items-center' style={{ width: '10%'}} >
							<Test className='mx-auto' />
						</div>
						<div className='ml-2' style={{ width: '85%'}}>
							<div className='text-blue font-bold' >
								Proporciona toda las pruebas que se te piden
							</div>
							<div>Para que se te pague solo deberás demostrarnos que te sucedió el incidente</div>
						</div>
					</div>
				</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<IconExpand />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>¿Cuándo y cómo se paga?</Typography>
            </AccordionSummary>
            <AccordionDetails>
				<div className='flex flex-col' >
					<div className='flex flex-wrap items-center' >
						<div className='flex items-center' style={{ width: '10%'}} >
							<Subscription className='mx-auto' />
						</div>
						<div className='ml-2' style={{ width: '85%'}}>
							<div className='text-blue font-bold' >
								Suscripción mensual
							</div>
							<div>El primer mes se cobrará inmediatamente. Los cargos mensuales subsiguientes comenzarán un mes después del inicio de su cobertura. El precio no cambiará.</div>
						</div>
					</div>
					<div className='flex flex-wrap items-center pt-6' >
						<div className='flex items-center' style={{ width: '10%'}} >
							<CreditCard className='mx-auto' />
						</div>
						<div className='ml-2' style={{ width: '85%'}}>
							<div className='text-blue font-bold' >
								Pagos con tarjeta
							</div>
							<div>Las primas se pagan automáticamente con cualquier tarjeta de crédito o débito. Los datos de su tarjeta serán procesados de forma segura por Stripe de acuerdo con las normas más estrictas del sector.</div>
						</div>
					</div>
				</div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<IconExpand />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
                <Typography className={classes.heading}>¿Cuándo empieza y termina la cobertura?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    La cobertura comienza en la fecha que usted elija.<br/>
                    La cobertura finaliza en el momento que dejes de pagar tu suscripción mensual, así de simple.
                </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<IconExpand />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
                <Typography className={classes.heading}>¿Cómo puedo cancelar el contrato?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    Puede cancelar su suscripción en cualquier momento desde la app o en nuestro sitio web para el final del ciclo mensual actual.
                </div>
            </AccordionDetails>
          </Accordion>
        </div>
      );
}
