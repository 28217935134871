import { useLocation } from "react-router-dom";

export default function OpenApp() {
    let location = useLocation();
    const reloadPage = () => {
        let url = new URLSearchParams(location.search);
        switch (url.get('route')) {
            case "home" :
                window.location.replace("gbactoio://");
                break;
            default:
                window.location.replace("gbactoio://payment");
                break;
        }
    }

    return(
        <>{ reloadPage() }</>
    )
}