import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import DownloadSection from 'components/faq/Download';
import Header from 'components/faq/Header';
import TabSection from 'components/faq/Tabs';

export default function FAQ() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <TabSection />
                <DownloadSection />
            </main>
            <DefaultFooter />
        </>
    );
}
