import DineroM from 'assets/img/paper/dinero.png';
import EconomistaM from 'assets/img/paper/economista.png';
import FinancieroM from 'assets/img/paper/financiero.png';
import MilenioM from 'assets/img/paper/milenio.png';
import UniversalM from 'assets/img/paper/universal.png';
//import { ReactComponent as Universal } from 'assets/img/paper/universal.svg';
import TeleAmigaM from 'assets/img/paper/teleAmiga.png';
import Informador from 'assets/img/paper/informador.png';
import Marquee from 'react-fast-marquee';

export default function TeamSection() {
    return (
        <section>
            <div className="container mx-auto md-hidden max-w-full mt-10">
                <div className="text-acto mx-auto text-center text-48 font-bold mb-12" >
                    Lo que dicen de nosotros
                </div>
                <div className="flex flex-wrap mx-auto" style={{height: 200}}>
                    <Marquee
                        speed={40}
                        pauseOnClick={true}
                    >
                        <div className="zoom">
                            <img src={DineroM} alt={"Dinero"} className="mx-auto" style={{width:'55%'}}></img>
                        </div>
                        <div className="zoom">
                            <a 
                                href="https://www.elfinanciero.com.mx/mundo-empresa/2023/01/16/celebran-alianza-mapfre-y-grupo-acto-en-beneficio-de-las-familias-mexicanas/" 
                                target="_blank" rel="noreferrer">
                                <img src={FinancieroM} alt="Financiero" className="mx-auto" style={{width:'80%'}} ></img>
                            </a>
                        </div>
                        <div className="zoom">
                            <a href='https://www.youtube.com/watch?v=lHLAhI3du_0' target={'_blank'} rel={'noreferrer'}>
                                <img src={TeleAmigaM} alt="Tele Amiga" className="mx-auto" style={{width:'50%'}} ></img>
                            </a>
                        </div>
                        <div className="zoom" style={{width: 400}}>
                            <img src={Informador} alt='informador' className="mx-auto" style={{width:'70%'}} ></img>
                        </div>
                        <div className="zoom" >
                            <img src={EconomistaM} alt={"Economista"} className="mx-auto" style={{width:'70%'}}></img>
                        </div>
                        <div className="zoom" style={{width: 250}}>
                            <img src={UniversalM} alt={"Universal"} className="mx-auto" style={{width:'70%'}} ></img>
                        </div>
                        <div className="zoom">
                            <img src={MilenioM} alt={"Milenio"} className="mx-auto" style={{width:'60%'}} ></img>
                        </div>
                    </Marquee>
                </div>
            </div>
            <div className="container max-w-full mx-auto lg:hidden mt-12 pt-10">
                <div className="text-acto mx-auto text-center font-bold mb-4 text-2xl" >
                    Lo que dicen de nosotros
                </div>
                <div className="flex flex-wrap mx-auto" style={{height: 100}}>
                    <Marquee
                        speed={50}
                        pauseOnHover={true}
                    >
                        <div className="zoom" >
                            <img src={DineroM} alt={"DineroM"} className="mx-auto" style={{width:'60%'}} ></img>
                        </div>
                        <div className="zoom" >
                            <a 
                                href="https://www.elfinanciero.com.mx/mundo-empresa/2023/01/16/celebran-alianza-mapfre-y-grupo-acto-en-beneficio-de-las-familias-mexicanas/" 
                                target="_blank" rel="noreferrer">
                                <img src={FinancieroM} alt={"FinancieroM"} className="mx-auto" style={{width:'100%'}} ></img>
                            </a>
                        </div>
                        <div className="zoom" >
                            <a href='https://www.youtube.com/watch?v=lHLAhI3du_0' target={'_blank'} rel={'noreferrer'}>
                                <img src={TeleAmigaM} alt="Tele Amiga " className="mx-auto" style={{width:'50%'}} ></img>
                            </a>
                        </div>
                        <div className="zoom" >
                            <img src={Informador} alt="Informador" className="mx-auto" style={{width:'75%'}} ></img>
                        </div>
                        <div className="zoom" >
                            <img src={EconomistaM} alt={"EconomistaM"} className="mx-auto" style={{width:'70%'}} ></img>
                        </div>
                        <div className="zoom" >
                            <img src={MilenioM} alt={"MilenioM"} className="mx-auto" style={{width:'65%', marginTop: -5}} ></img>
                        </div>
                        <div className="zoom">
                            <img src={UniversalM} alt={"UniversalM"} className="mx-auto" style={{width:'60%'}} ></img>
                        </div>
                    </Marquee>
                </div>
            </div>
        </section>
    );
}
