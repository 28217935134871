import { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
// import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import Button from '@material-tailwind/react/Button';
import { ReactComponent as Logo } from 'assets/img/logo.svg';

import Shopping from 'assets/img/shopping.svg'
import Message from 'assets/img/ballon.svg'
import Questions from 'assets/img/questions.png'
import Android from 'assets/img/mobile/header/android.png'
import IOS from 'assets/img/mobile/header/ios.png'

export default function DefaultNavbar({props}) {
    const [openNavbar, setOpenNavbar] = useState(false);
    const pathname = window.location.pathname;

    return (
        <Navbar className="bg-acto py-1.5" color="transparent" navbar>
            <div className="logo-center md-hidden" >
                <Link to="/">
                    <Logo />
                </Link>
            </div>
            <NavbarContainer className="NavbarContainer" >
                <NavbarWrapper className="items-center" >
                    <NavbarToggler
                        onClick={() => setOpenNavbar(!openNavbar)}
                        color="white"
                    />
                    <a
                        href="/preguntas-frecuentes"
                        target="_top"
                        rel="noreferrer"
                        className='lg:hidden'
                    >
                        <img style={{width:31}} className="mx-auto" src={Questions} alt='questions'/>
                    </a>
                </NavbarWrapper>
                <NavbarCollapse open={openNavbar} className="md-bg-white">
                    <Nav>
                        <div className="flex flex-col z-50 lg:flex-row lg:items-center banner">
                            <NavLink
                                href="/beneficios"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <span className='text-menu text-md md:text-md sm:text-2xl'>&nbsp;Beneficios</span>
                            </NavLink>
                            <NavLink
                                href="https://compras.acto.com.mx/subscription.php "
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <span className='text-menu text-md md:text-md sm:text-2xl'>&nbsp;Suscríbete</span>
                            </NavLink>
                            <NavLink
                                href="/nosotros"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <span className='text-menu text-gray-500 text-md md:text-md sm:text-2xl'>&nbsp;Nosotros</span>
                            </NavLink>
                            <NavLink
                                href="/company"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <span className='text-menu font-light text-md md:text-md sm:text-2xl'>&nbsp;Empresas</span>
                            </NavLink>
                            <div style={{height:20}}>

                            </div>
                            <NavLink
                                href={`${pathname}#download`}
                                target="_top"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <span className='text-menu font-light text-md md:text-md sm:text-2xl'>&nbsp;Descarga la app</span>
                            </NavLink>
                            <NavLink
                                href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                                id="navbar-ios"
                            >
                                <img src={IOS} alt='App iOS' style={{width: 175, height: 51}} />
                            </NavLink>
                            <NavLink
                                href="https://play.google.com/store/apps/details?id=com.acto.seguros"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                                id="navbar-android"
                            >
                                <img src={Android} alt='App Android' style={{width: 175, height: 51}}/>
                            </NavLink>
                            <a
                                href="https://compras.acto.com.mx"
                                target="_blank"
                                rel="noreferrer"
                                className='md-hidden'
                            >
                                <Button
                                    color="transparent"
                                    className="bg-color-acto text-white ml-4 p-1 font-normal"
                                    ripple="dark"
                                >
                                    <span className='text-sm'>Iniciar Sesión</span>
                                </Button>
                            </a>
                            <a
                                href="/preguntas-frecuentes"
                                target="_top"
                                rel="noreferrer"
                                className='md-hidden pl-4 pr-2'
                            >
                                <img style={{width:31}} className="mx-auto" src={Questions} alt='questions mobile'/>
                            </a>
                            <a
                                href={`https://wa.me/+5213314483372?text=${encodeURIComponent('¡Hola! Necesito ayuda')}`}
                                target="_blank"
                                rel="noreferrer"
                                className='md-hidden px-2'
                            >
                                <img className="mx-auto" src={Message} style={{width: 31, height: 31}} alt='message'/>
                            </a>
                            <a
                                href="/"
                                target="_top"
                                rel="noreferrer"
                                className='md-hidden pl-2'
                            >
                                <img src={Shopping} alt='shopping'/>
                            </a>
                        </div>
                    </Nav>
                </NavbarCollapse>
                <div className='float-faq'>
                    <a
                        href={`https://wa.me/+5213314483372?text=${encodeURIComponent('¡Hola! Necesito ayuda')}`}
                        target="_blank"
                        rel="noreferrer"
                        className='lg:hidden '
                    >
                        <img className="mx-auto px-1" style={{width: 64, height: 64}} src={Message} alt='message'/>
                    </a>
                </div>
            </NavbarContainer>
        </Navbar>
    );
}
