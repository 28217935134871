import Button from '@material-tailwind/react/Button';

export default function MomentSection() {
    return (
        <>
            <section className="bg-moment md-hidden">
                <div className="container max-w-7xl mx-auto px-4">
                    <div className="flex flex-wrap">
                    <div className="w-full md:w-8/12" ></div>
                        <div className="w-full md:w-4/12" >
                            <div className="flex flex-wrap text-left">
                                <div className="text-acto font-bold text-40 py-4">
                                    Asistencia al<br/>
                                    Momento
                                </div>
                                <div className="text-gray-700 text-30 md:text-2xl py-4" >
                                    Pide un médico a<br/>
                                    domicilio cuando lo<br/>
                                    necesites
                                </div>
                                <div className="py-4">
                                    <a
                                        href="/beneficios"
                                        target='_self'
                                        rel="noreferrer"
                                    >
                                        <Button
                                            color="transparent"
                                            className="bg-color-acto text-white text-normal text-xl rounded-6xl px-9"
                                            ripple="dark"
                                        >
                                            Conoce más beneficios
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lg:hidden">
                <div className='bg-moment' />
                <div className="container flex flex-wrap max-w-6xl mx-auto">
                    <div className="w-full text-center">
                        <div className="flex flex-col">
                            <div className="text-acto font-bold text-3xl leading-none">
                                Asistencias al<br/>
                                momento
                            </div>
                            <div className="text-gray-800 leading-none-forced text-xl pt-3" >
                                Pide un médico,<br/>
                                ambulancia o grúa<br/>
                                cuando mas lo<br/>
                                necesites
                            </div>
                            <div className="mx-auto pt-4">
                                <a
                                    href="/beneficios"
                                    target='_self'
                                    rel="noreferrer"
                                >
                                    <Button
                                        color="transparent"
                                        className="bg-color-acto text-white text-normal text-xl rounded-4xl"
                                        ripple="dark"
                                    >
                                        Conoce más beneficios
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
