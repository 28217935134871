import { ReactComponent as LogoPink } from 'assets/img/acto-pink.svg';
import { ReactComponent as Mapfre } from 'assets/img/phone/mapfre.svg';
// import { ReactComponent as Android } from 'assets/img/mobile/androidApp.svg';
// import { ReactComponent as Ios } from 'assets/img/mobile/iOSApp.svg';
import Android from 'assets/img/mobile/androidApp.png'
import Ios from 'assets/img/mobile/iOSApp.png'
import FolderWeb from 'assets/img/phone/folder.png';
import PiggyBank from 'assets/img/phone/piggyBank.png';
import CallWeb from 'assets/img/phone/call.png';
import AmbulanceWeb from 'assets/img/phone/ambulance.png';

import Folder from 'assets/img/mobile/phone/folder.png';
import EmergencyCall from 'assets/img/mobile/phone/phone.png';
import SaveMoney from 'assets/img/mobile/phone/save-money.png';
import AmbulanceMobile from 'assets/img/mobile/phone/ambulance.png';
import MapfreMobile from 'assets/img/mobile/phone/mapfre.png';

export default function Experience() {
    return (
        <section className="">
            <div className="container max-w-7xl mx-auto px-4 md-hidden bg-phone" style={{}}>
                <div className="flex flex-wrap justify-center items-center text-acto text-77 font-bold" >
                    Experiencia <LogoPink style={{width: 209}} /> 
                </div>
                <div className="flex flex-wrap">
                    <div className="lg:w-6/12 text-center" >
                        <div className="folder mt-24">
                            <div>
                                <img src={AmbulanceWeb} className="mx-auto mb-4" style={{width: 120}} alt='Folder'/>
                            </div>
                            <div className="text-26 md:text-2xl text-acto font-bold" >
                                Asistencias al Momento
                            </div>
                            <div className="text-xl text-gray-700" >
                            Si necesitas una ambulancia, Grúa, Medico a domicilio, Asistencia automotriz o Asistencia Vial podrás solicitarlo desde nuestra APP.
                            </div>
                        </div>
                        <div className="piggyBank">
                            <div>
                                <img src={PiggyBank} className="mx-auto mb-2" style={{width: 120}} alt='PiggyBank'/>
                            </div>
                            <div className="text-26 md:text-2xl text-acto font-bold" >
                                Dinero en Efectivo
                            </div>
                            <div className="text-xl text-gray-700" >
                                Si tuviste algún accidente obtén dinero en efectivo en menos de 72 horas.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap lg:w-6/12 justify-end text-center" style={{marginTop: "15.75rem"}}>
                        <div className="call">
                            <div className="ml-auto">
                                <img src={CallWeb} className="mx-auto mb-1" style={{width: 96}} alt='PiggyBank'/>
                            </div>
                            <div className="text-26 text-acto font-bold" >Atención 24/7</div>
                            <div className="text-xl text-gray-700" >
                                Obtén información de tu cobertura en todo momento o aclara cualquier duda.
                            </div>
                        </div>
                        <div className="ambulance mt-12" >
                            <div >
                                <img src={FolderWeb} className="mx-auto mb-1" style={{width: 109}} alt='PiggyBank'/>
                            </div>
                            <div className="text-26 text-acto font-bold" >Seguridad y Respaldo</div>
                            <div className="text-xl text-gray-700" >
                                <div className="flex flex-wrap justify-center items-center">
                                    Estamos respaldados por <Mapfre style={{marginLeft: "0.3rem", marginTop: 1}}/>
                                </div>
                                la aseguradora mundial número uno de Latinoamérica.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto lg:hidden" style={{marginTop: "4.125rem"}}>
                <div className="flex flex-wrap text-acto text-3xl font-bold justify-center items-center" >
                    Experiencia <LogoPink className="ml-4" style={{width: 106, height: 44}} /> 
                </div>
                <div className="flex flex-wrap text-center text-md pt-4">
                    <div className="w-full pb-6">
                        <div>
                            <img src={AmbulanceMobile} className="mx-auto" style={{width: 84}} alt='Ambulance Mobile'/>
                            <div className='text-acto font-bold pt-2'>
                                Asistencia al momento
                            </div>
                            <div >
                                Si necesitas una ambulancia, Grúa,<br/>
                                Medico a domicilio, Asistencia<br/>
                                automotriz o Asistencia Vial<br/>
                                podrás solicitarlo desde nuestra APP.
                            </div>
                        </div>
                    </div>
                    <div className="w-full pb-6">
                        <div className='p-1' >
                            <img src={SaveMoney} className="mx-auto" style={{width: 80}} alt='Piggy bank mobile'/>
                            <div className='text-acto font-bold pt-2'>
                                Dinero en Efectivo
                            </div>
                            <div >
                                Si tuviste algún accidente obtén<br/>
                                dinero en efectivo en menos de<br/>
                                72 horas.
                            </div>
                        </div>
                    </div>
                    <div className="w-full pb-6">
                        <div className='' >
                        <img src={EmergencyCall} className="mx-auto" style={{width: 68}} alt='Phone Mobile'/>
                            <div className='text-acto font-bold pt-2'>
                                Atención 24/7
                            </div>
                            <div>
                                Obtén información de tu cobertura en<br/>
                                todo momento o aclara cualquier<br/>
                                duda.
                            </div>
                        </div>
                    </div>
                    <div className="w-full pb-6">
                        <div className='' >
                            <img src={Folder} className="mx-auto" style={{width: 76}} alt='Folder mobile'/>
                            <div className='text-acto font-bold pt-2'>
                                Seguridad y Respaldo
                            </div>
                            <div className='flex flex-wrap justify-center items-center'>
                                Estamos respaldados por
                                <img src={MapfreMobile} className='mx-2' style={{width: 78, height: 10}} alt='mapfre text'/>
                                la
                            </div>
                            <div>
                                aseguradora mundial número uno de <br/>
                                Latinoamérica.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-phone flex flex-wrap justify-end items-end'>
                    <div style={{width: "39%", height: "43%"}} className='pr-4' >
                        <div className='w-full text-right text-white font-bold text-2xl pb-4'>
                            Descarga<br/>
                            la App
                        </div>
                        <div className='flex justify-end pb-4' >
                            <a href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100" rel="noreferrer" target="_blank" >
                                <img src={Ios} alt='App iOS' />
                            </a>
                        </div>
                        <div className='flex justify-end' >
                            <a href="https://play.google.com/store/apps/details?id=com.acto.seguros" rel="noreferrer" target="_blank">
                                <img src={Android} alt='App Android' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
