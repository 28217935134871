import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';

const { innerWidth: width} = window;

const Mobile = () => {
    return width <= 432 || false;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: Mobile()? 18 : 24,
        fontWeight: theme.typography.fontWeightBold,
    },
    tabWidth: {
        width: '85%',
        marginLeft: '0.5rem',
        fontSize: Mobile()? 14: 18
    }
  }));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'white',
        border: '1.5px solid #5900B4',
        borderRadius: 12,
        margin: '1rem 0',
        paddingLeft: 32,
        color: '#5900B4',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {
        backgroundColor: '#5900B4',
        color: 'white'
    },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        border: '1.5px solid #5900B4',
        borderTop: 'none',
        marginTop: '-1.5rem',
        borderRadius: '0 0 12px 12px',
        padding: Mobile()? '36px 20px' : '40px 24px',
        color: '#58595B',
        fontSize: Mobile()? 14 : 18
    },
}))(MuiAccordionDetails);


export default function AccordionExclusions() {
    const classes = useStyles();
    const Warning = () => <div style={{fontSize: Mobile()? 60 : 90}} className='text-warning mx-auto' >!</div>;
    const IconExpand = () => <ExpandMoreIcon style={{backgroundColor: '#007EFF', borderRadius: 15, color: 'white'}} />;

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading} >Ambulancia a domicilio</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col' >
                        <div >
                            <div className='text-blue font-bold' >
                                Puedes solicitar desde la app sin costo para ti hasta 2 ambulancias al año.
                                <br/>
                                24 horas al día los 365 días del año.
                            </div>
                            Ya sea por emergencia médica, accidente, enfermedad grave y/o traslado programado.<br/>
                            <br/>
                            <span className='text-blue font-bold' >
                                Hay 3 tipos de ambulancia
                            </span> y serán enviados de acuerdo a la necesidad de la situación:<br/>
                            <br/>
                            <div className='text-blue font-bold' >
                                1. Ambulancia terrestre de traslado con un mínimo de equipamiento para transportar pacientes que no se encuentran en riesgo de vida o salud.<br/>
                                2. Ambulancia terrestre con equipamiento para traslado y atención de salud del paciente.<br/>
                                3. Ambulancia terrestre utilizada para el traslado y atención médica avanzada de pacientes en estado crítico o de alto riesgo.<br/>
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div className='text-warning' >No aplica:</div>
                                La asistencia no podrá ser utilizada cuando se trate de lesiones o padecimientos que no ameriten traslado en ambulancia.<br/>
                                En caso de COVID-19 y SARS-COV-2, así como cualquier derivado, variación o cualquier mutación de ambos.<br/>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <div className={classes.heading} >Médico a domicilio</div>
                </AccordionSummary>
                <AccordionDetails>
                <div className='flex flex-col' >
                    <div>
                        Puedes solicitar desde la app medico a domicilio sin costo para ti hasta 4 eventos por año.
                        24 horas al día los 365 días del año.<br/>
                        <div className='text-blue font-bold' >Doctores de la red médica de aseguradora Mapfre.</div>
                    </div>
                    <div className='flex flex-wrap justify-between items-center pt-6'>
                        <Warning />
                        <div className={classes.tabWidth} >
                            <div className='text-warning' >No aplica:</div>
                            La asistencia no podrá ser utilizada cuando se trate de lesiones o padecimientos que no ameriten traslado en ambulancia.<br/>
                            En caso de COVID-19 y SARS-COV-2, así como cualquier derivado, variación o cualquier mutación de ambos.<br/>
                        </div>
                    </div>
                </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <div className={classes.heading} >Grúa</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col' >
                        <div>
                            Puedes solicitar desde la app sin costo para ti hasta 4 eventos por año<br/>
                            24 horas al día los 365 días del año<br/>
                            <div className='text-blue font-bold' >
                                En caso de avería que no permita la circulación del vehículo.
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div className='text-warning' >No aplica:</div>
                                En caso de choque.<br/>
                                Cuando el solicitante no se encuentra en el lugar de los hechos.<br/>
                                Que el vehículo no cuente con todas las llantas.
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                >
                    <div className={classes.heading} >Asistencia vial</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>
                            Puedes solicitar para ti sin costo hasta 2 eventos por año.<br/>
                            24 horas al día los 365 días del año.<br/>
                            <div className='text-blue font-bold'>
                                En caso de avería menor que no permita la circulación del vehículo tienes a tu disposición sin costo:
                            </div>
                            <br/>
                            <span className='text-blue font-bold' >
                                Cambio de neumático:
                            </span> Se gestionará el envío de personal autorizado para cambio y/o inflado del mismo, siempre y cuando dispongas del equipamiento y de la llanta de repuesto.
                            <br/>
                            <span className='text-blue font-bold' >
                                Paso de corriente:
                            </span> Se gestionará el envío de personal autorizado para el paso de corriente.
                            <br/>
                            <span className='text-blue font-bold'>
                                Combustible:
                            </span> En caso de falta de combustible que impida el funcionamiento del coche, se gestionará el envío de personal autorizado para el suministro de combustible hasta un máximo de 10 litros.
                            <br/>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div className='text-warning'>
                                    No aplica:
                                </div>
                                Vehículos de transporte público<br/>
                                Vehículos de transporte de mercancías<br/>
                                Vehículos de peso superior a 3.5 toneladas<br/>
                                Vehículos híbridos o eléctricos<br/>
                                En caso de choque<br/>
                                Por fenómenos de la naturaleza, tales como inundaciones, terremotos, etc.<br/>
                                En caso de competencias, carreras o pruebas de velocidad.<br/>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <div className={classes.heading} >Efectivo por accidente</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>
                            En caso de cualquier accidente tendrás a tu disposición hasta $40,000 pesos para reembolsar los gastos presentados
                            <div className='text-blue font-bold' >Solicitud de reclamación 100% digital</div>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div className='text-warning' >No aplica:</div>
                                <div>Lesiones que resulten de accidentes sufridos por encontrarse bajo los efectos del alcohol, drogas , narcóticos o alucinógenos no prescritos como medicamentos.</div>
                                <div>Cualquier gasto realizado por acompañantes del asegurado durante la hospitalización y traslado de éste.</div>
                                <div>Cualquier lesión autoinfligida.</div>
                                <div>Hernias y eventraciones.</div>
                                <div>Cuando el asegurado viaje en motocicleta o vehículos similares, utilizados con fines laborales o profesionales.</div>
                                <div>Lesiones producidas como consecuencia de la práctica o actividad profesional de cualquier deporte.</div>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel6a-content"
                    id="panel6a-header"
                >
                    <div className={classes.heading} >Efectivo por cáncer</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>
                            <div>En caso de que te lleguen a detectar cáncer en todas sus formas (excepto piel/ IN SITU)  recibirás $150,00 pesos para hacerle frente a tu diagnóstico.</div>
                            <div>Sólo tienes que demostrarnos que fuiste diagnosticado</div>
                            <div>Solicitud de reclamación 100% digital</div>
                            <div>Periodo de espera para poder solicitar: 60 días</div>
                            <div>Se entenderá que existe cáncer cuando hay presencia, en algúnórgano, de células cancerosas (malignas) caracterizadas por su proliferación, crecimiento
                            descontrolado y la invasión de tejidos, incluyendo la extensión directa o su metástasis.</div>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div className='text-warning' >No aplica:</div>
                                <div>Cáncer de piel</div>
                                <div>Cáncer cérvico uterino</div>
                                <div>Padecimientos ocurridos a consecuencia de intervenciones quirúrgicas</div>
                                <div>Enfermedades congénitas</div>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel7a-content"
                    id="panel7a-header"
                >
                    <div className={classes.heading} >Efectivo por muerte accidental</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>
                            <div>En caso de muerte accidental, tú beneficiario recibirá $200,000 pesos en efectivo.</div>
                            <div>Desde el primer día te encuentras protegido.</div>
                            <div>Solicitud de reclamación 100% digital.</div>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div>No aplica:</div>
                                    La muerte que resulten de accidentes sufridos por el usuario al encontrarse bajo los efectos del alcohol,drogas, narcóticos o alucinógenos no prescritos como medicamento.
                                    La muerte sufrida al presentar servicio militar, naval, policiaco o en tiempo de guerra.
                                    Envenenamiento de cualquier origen o naturaleza.
                                    Cuando el asegurado viaje en motocicletas o vehículos similares, utilizados con fines laborales o profesionales.

                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel8a-content"
                    id="panel8a-header"
                >
                    <div className={classes.heading} >Efectivo por fractura</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>
                            <div>En caso de cualquier fractura recibirás hasta $40,000 pesos en efectivo</div>
                            <div>Desde el primer día te encuentras protegido</div>
                            <div>Solicitud de reclamación 100% digital</div>
                        </div>
                        <div className='flex flex-wrap justify-between items-center pt-6'>
                            <Warning />
                            <div className={classes.tabWidth} >
                                <div>No aplica:</div>
                                <div>Lesiones que resulten de accidentes sufridos por encontrarse bajo los efectos del alcohol, drogas , narcóticos o alucinógenos no prescritos como medicamentos.</div>
                                <div>Cualquier gasto realizado por acompañantes del asegurado durante la hospitalización y traslado de éste.</div>
                                <div>Cualquier lesión auto infligida.</div>
                                <div>Cuando el asegurado viaje en motocicleta o vehículos similares, utilizados con fines laborales o profesionales.</div>
                                <div>Lesiones producidas como consecuencia de la práctica o actividad profesional de cualquier deporte.</div>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel9a-content"
                    id="panel9a-header"
                >
                    <div className={classes.heading} >Efectivo por infarto al miocardio</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>En caso de infarto al miocardio recibirás $100,000 pesos para hacerle frente.</div>
                        <div>Sólo tienes que demostrarnos que fuiste diagnosticado</div>
                        <div>Solicitud de reclamación 100% digital</div>
                        <div>Periodo de espera para poder solicitar: 60 días</div>
                        <br/>
                        <div className='text-blue font-bold' >Se entenderá como infarto al miocardio, la muerte de una parte del músculo del miocardio como consecuencia de un aporte sanguíneo deficiente a la zona respectiva. El diagnóstico debe ser respaldado por una hospitalización debida a síntomas urgentes de un infarto al miocardio.</div>
                        <div className='text-blue font-bold' >Para proceder al pago de la indemnización se requiere tratamiento hospitalario superior a 48 horas.</div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel10a-content"
                    id="panel10a-header"
                >
                    <div className={classes.heading} >Efectivo por enfermedad cerebrovascular</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        <div>En caso de infarto al miocardio recibirás $50,00 pesos para hacerle frente.</div>
                        <div>Sólo tienes que demostrarnos que fuiste diagnosticado.</div>    
                        <div>Solicitud de reclamación 100% digital</div>    
                        <div>Periodo de espera para poder solicitar: 30 días</div>
                        <br/>
                        <div className='text-blue font-bold' >
                            Se entenderá como enfermedad cerebro vascular es el tratamiento médico secundario a la suspensión brusca y violenta de las funciones cerebrales fundamentales que produce secuelas neurológicas con duración superior a veinticuatro horas y que provoque lesiones
                            irreversibles y de carácter permanente. Incluye infarto de tejido cerebral, hemorragia intracraneal o subaracnoidea y la embolia de una fuente extracraneal.
                        </div>    
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel11a-content"
                    id="panel11a-header"
                >
                    <div className={classes.heading} >¿Cuándo no me encuentro protegido o que me excluye?</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-wrap justify-between items-center'>
                        <Warning />
                        <div className={classes.tabWidth} >
                            <div className='text-warning' >Condiciones pre-existentes</div>
                            <div>Cuando se cuenta con enfermedades contraídas y/o manifestadas antes de ser parte de Acto.</div>
                            <br/>
                            <div className='text-warning' >Alcohol y  drogas</div>
                            <div>Si el usuario se encuentra bajo los efectos de alcohol, drogas, narcóticos o alucinógenos que no sean prescritos como medicamentos.</div>
                            <br/>
                            <div className='text-warning' >Embarazo preexistente</div>
                            <div>No se cubrirán gastos referentes a embarazo si el usuario ya se encontraba embarazada antes de ser parte de Acto.</div>
                            <br/>
                            <div className='text-warning' >Periodo de espera</div>
                            <div>Se entiende como el tiempo que debe transcurrir después de la contratación para amparar los padecimientos de: Cáncer (60 días), Infarto al miocardio (60 días) y Enfermedad Cerebrovascular (30 días)</div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel12a-content"
                    id="panel12a-header"
                >
                    <div className={classes.heading} >¿Dónde estoy protegido?</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        Toda la república mexicana
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel13a-content"
                    id="panel13a-header"
                >
                    <div className={classes.heading} >¿Cuántas horas tardaré en recibir mi efectivo si hago uso de uno de mis beneficios como: efectivo por accidente?</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col'>
                        En menos de 72 horas estás recibiendo el dinero en tu cuenta de banco.
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}