
export default function Assistance() {
  return (
    <>
      <section className="bg-benefits-assistance md-hidden">
          <div className="container max-w-7xl mx-auto px-4">
              <div className="flex flex-wrap pt-14 lg:text-2xl md:text-2xl">
                <div className="w-full md:w-4/12" >
                  <div className="text-blue lg:text-5xl md:text-4xl font-bold mb-8" >
                    Asistencias
                  </div>
                  <div className="pb-4">
                    <span className="text-blue font-bold" >Ambulancia</span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Pide hasta dos ambulancias<br/>
                      para ti sin costo
                    </span>
                  </div>
                  <div className="pb-4" >
                    <span className="text-blue font-bold" >Médico a domicilio</span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Recibe citas médicas a domicilio.
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-blue font-bold" >Grúa</span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Pide hasta dos grúas <br/> para ti sin costo
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-blue font-bold" >Asistencia automotriz</span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Técnicos o mecánicos <br/> hasta 2 eventos al año
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-blue font-bold" >Asistencia vial</span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Ponchaduras<br/>
                      Cerrajero<br/>
                      Batería<br/>
                      Gasolina
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-8/12" >
                </div>
              </div>
          </div>
      </section>
      <section className="lg:hidden">
        <div className="container w-full">
            <div className="flex flex-wrap pt-10">
              <div className="bg-benefits-assistance w-full" />
              <div className="w-full text-center" >
                <div className="text-blue text-4xl font-bold mb-8" >
                  Asistencias
                </div>
                <div className="pb-4">
                  <span className="text-blue font-bold" >Asistencia automotriz</span><br/>
                  <span className="text-gray-500">
                    Técnicos o mecánicos <br/>hasta 2 eventos al año
                  </span>
                </div>
                <div className="pb-4">
                  <span className="text-blue font-bold" >Asistencia vial</span><br/>
                  <span className="text-gray-500">
                    Ponchaduras<br/>
                    Cerrajero<br/>
                    Batería<br/>
                    Gasolina
                  </span>
                </div>
                <div className="pb-4">
                  <div className="text-blue font-bold" >Ambulancia</div>
                  <span className="text-gray-500">
                    Hasta 2 eventos al año
                  </span>
                </div>
                <div className="pb-4">
                  <span className="text-blue font-bold" >Grúa</span><br/>
                  <span className="text-gray-500">
                    Hasta 2 eventos al año
                  </span>
                </div>
                <div className="pb-4" >
                  <span className="text-blue font-bold" >Médico a domicilio</span><br/>
                  <span className="text-gray-500">
                      Recibe citas médicas a domicilio.
                  </span>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
}
