import WorldAnimate from 'assets/img/world.gif';

export default function Mission() {
    return (
        <section>
            <div className="container max-w-7xl mx-auto md-hidden" style={{paddingTop: '10.5rem'}}>
                <div className='flex flex-wrap' >
                    <div className="w-full lg:w-6/12">
                        <img src={WorldAnimate} className='mx-auto' alt='world' style={{width:471}}/>
                    </div>
                    <div className="w-full lg:w-6/12" style={{paddingLeft: '3.625rem'}} >
                        <div className='text-acto-purple text-56 font-bold pb-8' >Nuestra Misión</div>
                        <div className='text-gray-700 text-2xl leading-10' >
                            Buscamos simplificar un problema de salud o <br/>
                            accidente, en Acto ofrecemos las <br/>
                            herramientas necesarias para ayudar a<br/>
                            nuestros <br/>
                            clientes a lograr una vida sin complicaciones<br/>
                            financieras en caso de tener algún tipo de<br/>
                            enfermedad grave, no grave o en caso de<br/>
                            tener un accidente.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container max-w-7xl mx-auto lg:hidden" style={{paddingTop: '5.25rem'}}>
                <div className='flex flex-wrap' >
                    <div className="w-full lg:w-6/12 text-center">
                        <div className='text-acto-purple font-bold pb-8' style={{fontSize: 40}} >
                            Nuestra Misión
                        </div>
                        <div className='text-gray-700 text-22'  >
                            Buscamos simplificar un <br/>
                            problema de salud o accidente, en<br/>
                            Acto ofrecemos las herramientas<br/>
                            necesarias para ayudar a nuestros<br/>
                            clientes a lograr una vida sin<br/>
                            complicaciones financieras en<br/>
                            caso de tener algún tipo de<br/>
                            enfermedad grave, no grave o en<br/>
                            caso de tener<br/>
                            un accidente.
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 py-10">
                        <img src={WorldAnimate} className='mx-auto' alt='world' />
                    </div>
                </div>
            </div>
        </section>
    )
}