
export default function Header() {
    return (
        <section>
            <div className="container max-w-7xl mx-auto text-right md-hidden" style={{paddingTop: '10.875rem'}}>
                <div className='text-6xl text-blue font-bold' >
                    ¿Tienes alguna duda?
                </div>
                <div className='flex justify-end text-gray-700 text-2xl' style={{marginTop: '4rem'}} >
                    Busca en nuestra biblioteca de ayuda
                </div>
            </div>
            <div className="container max-w-7xl mx-auto text-center lg:hidden" style={{paddingTop: '7rem'}}>
                <div className='text-blue font-bold' style={{fontSize: 40}} >
                    ¿Tienes alguna duda?
                </div>
                <div className='flex justify-center text-gray-700 text-2xl my-6' >
                    Busca en nuestra biblioteca de ayuda
                </div>
            </div>
        </section>
    )
}