import ios from 'assets/img/app/ios.png'
import android from 'assets/img/app/android.png'
import { ReactComponent as LogoBlue } from 'assets/img/acto-blue.svg';

export default function DownloadSection() {
    return (
        <>
        <section className="relative block bg-app md-hidden">
            <div className="container max-w-7xl mx-auto px-4" id="download">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-8/12" ></div>
                    <div className="w-full md:w-4/12" >
                        <div className="flex flex-wrap text-left pl-8">
                            <div className="text-acto font-bold text-42 py-2">
                                Descarga la App
                            </div>
                            <div className="flex flex-wrap text-blue text-4xl py-2" >
                                <div>Se parte de</div>
                                <div className='px-4' >
                                    <LogoBlue style={{marginTop: 5}}/>
                                </div>
                                <span className="pr-2">y</span><br/>
                                <div>vive libre</div>
                            </div>
                            <div className="pt-16 flex">
                              <div className='pr-3' >
                                  <a href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100" rel="noreferrer" target="_blank" >
                                    <img src={ios} alt='Logo iOS'></img>
                                  </a>
                              </div>
                              <div className='pr-3' >
                                  <a href="https://play.google.com/store/apps/details?id=com.acto.seguros" rel="noreferrer" target="_blank">
                                    <img src={android} alt='Logo android' ></img>
                                  </a>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-app lg:hidden" style={{height: 450, paddingTop: "1rem"}}>
            <div className="container max-w-7xl mx-auto" style={{marginTop: "-16rem"}}>
                <div className="flex flex-col text-center">
                    <div className="text-acto font-bold text-3xl pb-2">
                        Descarga la App Movil
                    </div>
                    <div className="text-blue text-xl " >
                        <div className="flex flex-wrap items-center justify-center" >
                            <div>Se parte de </div>
                            <div className='px-2' >
                                <LogoBlue style={{width: 50,height: 22,marginTop: 5}}/>
                            </div>
                            <div>y</div>
                        </div>
                        <div>vive libre</div>
                    </div>
                    <div className="pt-6 flex mx-auto justify-center" style={{width: "80%"}}>
                        <div className='pr-1' >
                            <a href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100" rel="noreferrer" target="_blank" >
                                <img class="logo-ios" src={ios} alt='Logo iOS' ></img>
                            </a>
                        </div>
                        <div className='' >
                            <a href="https://play.google.com/store/apps/details?id=com.acto.seguros" rel="noreferrer" target="_blank">
                                <img class="logo-android" src={android} alt='Logo android' ></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}
