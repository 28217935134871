const { innerWidth: width, innerHeight: height } = window;

export const widthWindow = width;
export const heightWindow = height;
export const isMobile = () => {
    return width <= 432;
}
export const scale = () =>{
    return width / 432;
}

export const getMobileOperatingSystem = () => {
    var userAgent = window.navigator.userAgent;
    console.log(userAgent)
    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "other";
}