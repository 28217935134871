import { isMobile } from 'components/Dimensions';
import LogoActo from 'assets/img/logoActoApp.png'
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { getMobileOperatingSystem } from './Dimensions';

export default function BannerApp(props){
    const ActoAppComponent = () => {
        const [openBanner, setOpenBanner] = useState(props.open);
        let component
        const changeStatusBanner = () => {
            setOpenBanner(false)
            localStorage.setItem('statusBanner', false);
        }

        switch (getMobileOperatingSystem()) {
            case "iOS":
                component = <Collapse in={openBanner}>
                            <div style={{width: "100%", padding: 10}}>
                                <div className='flex justify-between items-center text-sm' >
                                    <div className='flex items-center' >
                                        <div className='px-2'>
                                            <CloseIcon fontSize='small' onClick={() => changeStatusBanner()}/>
                                        </div>
                                        <img style={{width:42, borderRadius: 10}} src={LogoActo} alt="Banner Android" />
                                        <div className='px-4' >
                                            Cambia a la app para una mejor experiencia
                                        </div>
                                    </div>
                                    <div className='px-4' onClick={()=> changeStatusBanner()} >
                                        <a href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100" rel="noreferrer" target="_blank">
                                            <div className='px-4 py-2 rounded-lg border border-gray-500' >Abrir</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                break;
            case "Android":
                component = <Collapse in={openBanner}>
                                <div style={{width: "100%", padding: 10}}>
                                    <div className='flex justify-between items-center text-sm' >
                                        <div className='flex items-center' >
                                            <div className='px-2'>
                                                <CloseIcon fontSize='small' onClick={() => changeStatusBanner()}/>
                                            </div>
                                            <img style={{width:42, borderRadius: 10}} src={LogoActo} alt="Banner Android" />
                                            <div className='px-4' >
                                                Cambia a la app para una mejor experiencia
                                            </div>
                                        </div>
                                        <div className='px-4' onClick={()=> changeStatusBanner()} >
                                            <a href="https://play.google.com/store/apps/details?id=com.acto.seguros" rel="noreferrer" target="_blank">
                                                <div className='px-4 py-2 rounded-lg border border-gray-500' >Abrir</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                break;
            default:
                component = <></>
                break
        }

        return component;
    }

    return isMobile() && <ActoAppComponent />;
}