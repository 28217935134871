import { ReactComponent as Play } from 'assets/img/btn-play-yellow.svg';
import { ReactComponent as Instagram } from 'assets/img/social-media/instagram.svg';
import { ReactComponent as Facebook } from 'assets/img/social-media/facebook.svg';
import { ReactComponent as Twitter } from 'assets/img/social-media/twitter.svg';
import { ReactComponent as LinkedIn } from 'assets/img/social-media/linkedin.svg';
import { InputLabel, makeStyles, MenuItem, withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField'
import { Button } from '@material-tailwind/react';
import { useState } from 'react';
import FslightboxReact from "fslightbox-react";

const useStyles = makeStyles((theme) => ({
    root: {
      fontSize: 18,
      marginBottom: 8,
    },
  }));



export default function FormSection() {
    const classes = useStyles();
    const [position, setPosition] = useState('FUNDADOR');

    const [index, setIndex] = useState(0)
    const [show, setShow] = useState(false)
    const showVideo = (index) => {
        setIndex(index)
        setShow(!show)
    }
    const videos = [
        ["https://www.youtube.com/watch?v=nnkQgeus4hs"]
    ]

    const positions = [
        {
            value: 'CEO',
            label: 'CEO'
        },
        {
            value: 'FUNDADOR',
            label: 'Fundador'
        },
        {
            value: 'EMPLEADO',
            label: 'Empleado'
        },
        {
            value: 'ADMIN',
            label: 'Administrador'
        },
        {
            value: 'GERENTE',
            label: 'Gerente'
        },
        {
            value: 'DIRECTOR',
            label: 'Director/a'
        },
        {
            value: 'COFUNDADOR',
            label: 'Co-Fundador'
        },
        {
            value: 'CONSEJERO',
            label: 'Consejero'
        },
        {
            value: 'SOCIO',
            label: 'Socio/a'
        }

    ]

    const handleChange = (event) => {
        setPosition(event.target.value);
    };

    const TextField = withStyles({
        root: {
            "& .MuiOutlinedInput-root": {
                borderRadius: '1rem',
            },
            "& .MuiOutlinedInput-input": {
                padding: '8px 14px',
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                paddingRight: '32px',
                width: '100%'
            },
        }
	})(MuiTextField);

    return (
        <section>
            <div className="container max-w-7xl mx-auto md-hidden header-padding" >
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6/12 text-gray-800" >
                        <div className='text-2xl ' >
                            Más que un Acto
                        </div>
                        <div className='text-acto text-56 font-bold' >
                            Una plataforma <br/>
                            para cuidar de <br/>
                            todos
                        </div>
                        <div className='text-2xl pt-2'>
                            Con Acto Enterprise, tus colaboradores  pueden sentirse seguros y protegidos ante cualquier tipo de accidente.
                        </div>
                        <div className='flex flex-wrap items-center text-xl text-yellow pt-4 cursor-pointer' onClick={() => showVideo(0)} >
                            <Play className='mr-4' /> Mira como funciona
                        </div>
                        <div className='flex flex-wrap justify-between' style={{width: 140, marginTop: '3.5rem'}}>
                            <a
                                href='https://www.instagram.com/acto.io'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Instagram className='mx-auto cursor-pointer' />
                            </a>
                            <a
                                href='https://www.facebook.com/acto.io'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Facebook className='mx-auto cursor-pointer' />
                            </a>
                            <a
                                href='/'
                                target='_self'
                                rel='noreferrer'
                            >
                                <Twitter className='mx-auto' />
                            </a>
                            <a
                                href='https://www.linkedin.com/company/acto-membresias/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <LinkedIn className='mx-auto cursor-pointer' />
                            </a>
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 form-padding">
                        <div className='text-2xl text-acto font-bold' >Completa el formulario</div>
                        <div className='behind' ></div>
                        <div className='bg-form' >
                            <div className='flex flex-wrap justify-between pt-8' >
                                <div >
                                    <InputLabel className={classes.root} >Nombre Completo</InputLabel>
                                    <TextField 
                                        id="name"
                                        variant="outlined"
                                        placeholder="Samantha"
                                        />
                                </div>
                                <div>
                                    <InputLabel className={classes.root} >Apellido</InputLabel>
                                    <TextField
                                        id="last-name"
                                        variant="outlined" 
                                        placeholder="William"
                                        />
                                </div>
                            </div>
                            <div className='pt-4' >
                                <InputLabel className={classes.root} >Correo Empresarial</InputLabel>
                                <TextField
                                    id="email"
                                    variant="outlined" 
                                    placeholder="email@email.com"
                                    fullWidth
                                    />
                            </div>
                            <div className='flex flex-wrap justify-between pt-4' >
                                <div>
                                    <InputLabel className={classes.root} >Puesto</InputLabel>
                                    <TextField
                                        id="position"
                                        select
                                        variant="outlined" 
                                        placeholder="email@email.com"
                                        value={position}
                                        onChange={handleChange}
                                        classes={{
                                            root: {
                                                backgroundColor: 'red'
                                            }
                                        }}
                                        >
                                        {positions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div>
                                    <InputLabel className={classes.root} >Nombre Empresa</InputLabel>
                                    <TextField
                                        id="company"
                                        variant="outlined" 
                                        placeholder="Acto"
                                        />
                                </div>
                            </div>
                            <div className='flex flex-wrap pt-4' >
                                <div style={{width: '50%'}}>
                                    <InputLabel >Numero de Teléfono</InputLabel>
                                    <TextField
                                        id="charge"
                                        variant="outlined" 
                                        placeholder="+52 3354 4643"
                                        />
                                </div>
                            </div>
                            <div className='pt-4' >
                                <InputLabel className={classes.root} >Preguntas y Comentarios</InputLabel>
                                <TextField
                                    id="charge"
                                    variant="outlined" 
                                    fullWidth
                                    multiline
                                    rows={4}
                                    />
                            </div>
                            <Button
                                color="transparent"
                                className="btn-acto-pink"
                                ripple="dark"
                                
                            >
                                Enviar
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container max-w-7xl mx-auto lg:hidden" style={{paddingTop: 171, paddingBottom: 126}}>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 text-gray-800 text-center" >
                        <div className='text-2xl ' >
                            Más que un Acto
                        </div>
                        <div className='text-acto text-40 font-bold mt-2' >
                            Una plataforma <br/>
                            para cuidar de <br/>
                            todos
                        </div>
                        <div className='text-2xl mt-6'>
                            Con Acto Enterprise, tus<br/>
                            colaboradores pueden<br/>
                            sentirse seguros y<br/>
                            protegidos ante cualquier<br/>
                            tipo de accidente.
                        </div>
                        <div className='flex flex-wrap justify-center items-center text-xl text-yellow my-8 cursor-pointer' onClick={() => showVideo(0)} >
                            <Play className='mr-4' /> Mira como funciona
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12">
                        <div className='text-2xl text-acto font-bold text-center' >Completa el formulario</div>
                        <div className='flex justify-center'>
                            <div className='behind' ></div>
                            <div className='bg-form' >
                                <div className='mt-2' >
                                    <InputLabel className={classes.root}>Nombre Completo</InputLabel>
                                    <TextField 
                                        id="name"
                                        variant="outlined"
                                        placeholder="Samantha"
                                        fullWidth
                                        />
                                </div>
                                <div className='pt-8' >
                                    <InputLabel className={classes.root} >Apellido</InputLabel>
                                    <TextField
                                        id="last-name"
                                        variant="outlined" 
                                        placeholder="William"
                                        fullWidth
                                        />
                                </div>
                                <div className='pt-8' >
                                    <InputLabel className={classes.root} >Correo Empresarial</InputLabel>
                                    <TextField
                                        id="email"
                                        variant="outlined" 
                                        placeholder="email@email.com"
                                        fullWidth
                                        />
                                </div>
                                <div className='pt-8' >
                                    <InputLabel className={classes.root} >Puesto</InputLabel>
                                    <TextField
                                        id="position"
                                        select
                                        variant="outlined" 
                                        placeholder="email@email.com"
                                        value={position}
                                        onChange={handleChange}
                                        fullWidth
                                        >
                                        {positions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='pt-8' >
                                    <InputLabel className={classes.root} >Nombre Empresa</InputLabel>
                                    <TextField
                                        id="company"
                                        variant="outlined" 
                                        placeholder="Acto"
                                        fullWidth
                                        />
                                </div>
                                <div className='pt-8' >
                                    <InputLabel className={classes.root} >Numero de Teléfono</InputLabel>
                                    <TextField
                                        id="charge"
                                        variant="outlined" 
                                        placeholder="+52 3354 4643"
                                        fullWidth
                                        />
                                </div>
                                <div className='pt-8' >
                                    <InputLabel className={classes.root} >Preguntas y Comentarios</InputLabel>
                                    <TextField
                                        id="charge"
                                        variant="outlined" 
                                        fullWidth
                                        multiline
                                        rows={4}
                                        />
                                </div>
                                <Button
                                    color="transparent"
                                    className="btn-acto-pink rounded-4xl"
                                    ripple="dark"
                                    
                                >
                                    Enviar
                                </Button>
                                <div className='flex flex-wrap justify-between mx-auto' style={{width: 140, marginTop: '2.25rem', opacity: 0.5}}>
                                    <a
                                        href='https://www.instagram.com/acto.io'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Instagram className='mx-auto cursor-pointer' />
                                    </a>
                                    <a
                                        href='https://www.facebook.com/acto.io'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Facebook className='mx-auto cursor-pointer' />
                                    </a>
                                    <Twitter className='mx-auto cursor-pointer' />
                                    <a
                                        href='https://www.linkedin.com/company/acto-membresias/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <LinkedIn className='mx-auto cursor-pointer' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FslightboxReact 
                toggler={show}
                sources={videos[index]}
            />
        </section>
    );
}