import { ReactComponent as SuccessIcon } from 'assets/img/success.svg';
import { ReactComponent as BtnAndroid } from 'assets/img/app/android-pink.svg';
import { ReactComponent as BtnIos } from 'assets/img/app/ios-pink.svg';
import { isMobile } from 'components/Dimensions';

export default function Header() {
    const Layout = () => {
        return isMobile()? 
            <div className="container max-w-full relative mx-auto bg-success">
                <div className="flex flex-wrap flex-col justify-center items-center" style={{height: '82vh'}} >
                    <SuccessIcon />
                    <div className="text-30 text-blue font-bold pt-6">
                        ¡Tu pago es exitoso!
                    </div>
                    <div className="text-gray-700 pt-8 text-center px-2">
                        Hemos recibido tu pago con éxito, descarga la app para vivir una mejor experiencia y tener todos los beneficios a la mano.
                    </div>
                    <div className="flex flex-row pt-10" >
                        <a
                            href="https://play.google.com/store/apps/details?id=com.acto.seguros"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <BtnAndroid className='mr-4' style={{width: 139}}/>
                        </a>
                        <a
                            href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <BtnIos style={{width: 139}} />
                        </a>
                    </div>
                </div>
            </div>
        :
        <div className="container max-w-full relative mx-auto bg-success">
                <div className="flex flex-wrap flex-col justify-center items-center" style={{height: '82vh'}} >
                    <SuccessIcon />
                    <div className="text-30 text-blue font-bold pt-10">
                        ¡Tu pago es exitoso!
                    </div>
                    <div className="text-gray-700 pt-4 text-xl text-center" style={{width: 461}} >
                        Hemos recibido tu pago con éxito, descarga la app para vivir una mejor experiencia y tener todos los beneficios a la mano.
                    </div>
                    <div className="flex flex-row pt-10" >
                        <a
                            href="https://play.google.com/store/apps/details?id=com.acto.seguros"
                            rel="noreferrer"
                            target="_blank"
                            >
                            <BtnAndroid className='mr-4'/>
                        </a>
                        <a
                            href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100"
                            rel="noreferrer"
                            target="_blank"
                            >
                            <BtnIos />
                        </a>
                    </div>
                </div>
            </div>
    }    
    return (
        <div className="relative flex content-center items-center justify-center">
            <Layout />
        </div>
    );
}
