import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import { Button } from '@material-tailwind/react';

import logoActo from 'assets/img/faq/logo-pink.png'
import mapfre from 'assets/img/faq/mapfre.png'

const { innerWidth: width} = window;

const Mobile = () => {
    return width <= 432 || false;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: Mobile()? 18 : 24,
        fontWeight: theme.typography.fontWeightBold,
    }
  }));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'white',
      border: '1.5px solid #5900B4',
      borderRadius: 12,
      margin: '1rem 0',
      paddingLeft: 32,
      color: '#5900B4',
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {
        backgroundColor: '#5900B4',
        color: 'white'
    },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        border: '1.5px solid #5900B4',
        borderTop: 'none',
        marginTop: '-1.5rem',
        borderRadius: '0 0 12px 12px',
        padding: '40px 24px',
        color: '#58595B'
    },
}))(MuiAccordionDetails);


export default function AccordionSubscriptions() {
    const classes = useStyles();
    const IconExpand = () => <ExpandMoreIcon style={{backgroundColor: '#007EFF', borderRadius: 15, color: 'white'}} />;

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading} >¿Qué tipo de seguro ofrecemos?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col text-lg md:text-sm' >
                        La plataforma Acto es un excelente opción para personas que necesitan seguro de salud integral para poder hacerle frente a los gastos ante cualquier accidente o enfermedad grave que se presente y tener asistencias al momento, todo 100% digital. Ofrecemos cobertura desde el momento que te registras en una suscripción mensual que se puede cancelar en cualquier momento.
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading} >¿Cuánto cuesta?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex w-full flex-col text-2xl md:text-lg' >
                        <div className='py-8 md:py-4 text-blue font-bold' >$499 + iva al mes</div>
                        <div>
                            <Button
                                color="transparent"
                                className="btn-acto"
                                ripple="dark"
                            >
                                Conoce más beneficios
                            </Button>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className={classes.heading} >¿Quién está envuelto?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col text-lg'>
                        <div style={{width: 84}} >
                            <img src={logoActo} alt='acto-pink' />
                        </div>
                        <div className='text-lg text-gray-700 py-4 md:text-sm' >
                            Desarrollo de producto, servicio al cliente y manejo de reclamaciones.
                        </div>
                        <div style={{width: 256}}>
                            <img src={mapfre} alt='logo-mapfre' style={{marginLeft: '-22px'}} />
                        </div>
                        <div className='text-lg md:text-sm' >
                            Seguros, asistencias y portador del riesgo.
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconExpand />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                >
                    <Typography className={classes.heading} >¿Qué incluye mi suscripción?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex flex-col text-lg md:text-sm' >
                        <div>
                            Desde el momento que pagas tu suscripción estás protegido y podrás contar con los siguientes beneficios al ser parte de Acto.
                        </div>
                        <div className='text-xl font-bold text-blue pt-4 md:text-lg' >
                            Asistencias
                            <ul className='text-lg list-disc ml-8 pt-4 md:text-sm'  >
                                <li>Ambulancia</li>
                                <li>Médico a domicilio</li>
                                <li>Grúa</li>
                                <li>Asistencia automotriz</li>
                                <li>Asistencia vial</li>
                            </ul>
                        </div>
                        <div className='text-xl font-bold text-pink pt-4 md:text-lg' >
                            Protecciones
                            <ul className='text-lg list-disc ml-8 pt-4 md:text-sm' >
                                <li>Efectivo por accidente</li>
                                <li>Efectivo por cáncer</li>
                                <li>Efectivo por muerte accidental</li>
                                <li>Efectivo por fractura</li>
                                <li>Efectivo por infarto al miocardio</li>
                                <li>Efectivo por enfermedad vascular cerebral</li>
                            </ul>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}