import form from "assets/img/need/12.png"
import phone from "assets/img/need/13.png"
import girl from "assets/img/need/16.png"
import { ReactComponent as Form } from 'assets/img/need/complete.svg';
import { ReactComponent as Phone } from 'assets/img/need/touchPink.svg';
import { ReactComponent as Girl } from 'assets/img/need/done.svg';

export default function Protection() {
  return (
    <>
      <section className="bg-proteccion md-hidden" >
          <div className="container max-w-7xl mx-auto px-4 text-gray-700">
              <div className="text-pink mx-auto text-center text-40" style={{paddingBottom: "6.5rem"}}>
              Solicita una protección en 3 pasos
              </div>
              <div className="flex flex-wrap justify-center text-lg mx-auto text-center text-2xl" style={{width: "90%"}}>
                <div className="px-4 sm:w-4/12" >
                  <div>
                    <img className="mx-auto" style={{height: 263}} src={phone} alt="phone" />
                  </div>
                  <div className="py-8">
                    1. Elige la <span className="text-pink font-bold" >protección</span><br/> que necesitas
                  </div>
                </div>
                <div className="px-4 sm:w-4/12" >
                  <div>
                    <img className="mx-auto" style={{height: 263}} src={form} alt="form" />
                  </div>
                  <div className="py-8">
                    2. <span className="text-pink font-bold" >Llena la solicitud</span>
                  </div>
                </div>
                <div className="sm:w-4/12" >
                  <div className="px-4" >
                    <img className="mx-auto" style={{height: 263}} src={girl} alt="girl" />
                  </div>
                  <div className="py-8">
                    3. <span className="text-pink font-bold" >¡Listo!</span>
                    <br/>
                    Recibe tu dinero en menos de 72 horas. Una vez tu expediente este completado.
                    <br/>
                    <div className="text-xs px-2" >* Si quieres saber cómo va tu proceso accede a la selección de “Mis reclamaciones”.</div>
                  </div>
                </div>
              </div>
          </div>
      </section>
      <section className="lg:hidden">
          <div className="container max-w-6xl mx-auto px-4 " style={{color: "#58595B"}}>
              <div className="text-pink mx-auto text-center text-xl pb-12" >
			  	      Solicita una protección en 3 pasos
              </div>
              <div className="flex flex-wrap justify-center text-xl text-center">
                <div className="w-full leading-none" >
                  <div >
                    <div>
                      <Phone className="mx-auto" style={{width: '100%'}} />
                    </div>
                    <div className="py-8">
                      1. Elige la <span className="text-pink" >protección</span><br/> que necesitas
                    </div>
                  </div>
                </div>
                <div className="w-full leading-none" >
                  <div>
                    <Form className="mx-auto" style={{width: '100%'}} />
                  </div>
                  <div className="py-8">
                    2. <span className="text-pink" >Llena la solicitud</span>
                  </div>
                </div>
                <div className="w-full leading-none" >
                  <div>
                    <Girl className="mx-auto" style={{width: '100%'}} />
                  </div>
                  <div className="py-8">
                    3. <span className="text-pink" >¡Listo!</span><br/>
                    Recibe tu dinero en menos de<br/>
                    72 hrs. Una vez tu expediente este completado.<br/>
                    <span className="text-sm" >* Si quieres saber como va tu proceso<br/> accede a la sección de "Mis<br/> reclamaciones".</span>
                  </div>
                </div>
              </div>
          </div>
      </section>
    </>
  );
}