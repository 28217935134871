import Ios from 'assets/img/app/ios.png'
import Android from 'assets/img/app/android.png'
import { ReactComponent as LogoBlue } from 'assets/img/acto-blue.svg';

export default function DownloadSection() {
    return (
        <>
            <section className="bg-app-benefits md-hidden">
                <div className="container max-w-7xl mx-auto " id="download">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-7/12" />
                        <div className="w-full md:w-5/12 pl-4" >
                            <div className="flex flex-wrap text-left">
                                <div className="text-acto font-bold text-60 pb-4">
                                    Descarga la App
                                </div>
                                <div className="flex flex-wrap text-blue text-4xl" >
                                    <div>Se parte de</div>
                                    <div className='px-4' >
                                        <LogoBlue style={{marginTop: 5}}/>
                                    </div>
                                    <span className='pr-2' >y</span><br/>
                                </div>
                                <div className="flex w-full text-blue text-4xl" >
                                    <div> vive libre</div>
                                </div>
                                <div className="py-10 flex">
                                <div className='pr-3' >
                                    <a href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100" rel="noreferrer" target="_blank" >
                                        <img src={Ios} alt="logo iOS" ></img>
                                    </a>
                                </div>
                                <div className='pr-3' >
                                    <a href="https://play.google.com/store/apps/details?id=com.acto.seguros" rel="noreferrer" target="_blank">
                                        <img src={Android} alt="logo android"></img>
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lg:hidden pb-10">
                <div className="container w-full">
                    <div className="flex flex-wrap">
                        <div className="mx-auto text-center" style={{width: "80%", marginBottom: "-5rem"}} >
                            <div className="flex flex-col">
                                <div className="text-acto font-bold text-2xl pb-2">
                                    Descarga la App
                                </div>
                                <div className="text-blue text-lg" >
                                    Se parte de Acto y<br/>
                                    vive libre
                                </div>
                                <div className="pt-10 flex justify-center">
                                    <div className='pr-3' >
                                        <a href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100" rel="noreferrer" target="_blank" >
                                            <img class="logo-ios" src={Ios} alt="logo iOS" ></img>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=com.acto.seguros" rel="noreferrer" target="_blank">
                                            <img class="logo-android" src={Android} alt="logo android"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full bg-app" />
                    </div>
                </div>
            </section>
        </>
    );
}
