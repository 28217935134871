import { Button } from "@material-tailwind/react";


export default function Documents() {
    return (
        <div className='pt-8' >
            <div className='md-hidden'>
                <div className='text-acto text-2xl font-bold' >¿Quieres saber más?</div>
                <div className='text-lg text-gray-700 pt-6' >Estos documentos contienen todo lo que necesita saber:</div>
                <div className='pt-6' >
                    <Button
                        color="transparent"
                        className="btn-acto"
                        ripple="dark"
                    >
                        Condiciones de consumidor
                    </Button>
                </div>
                <div className='pt-4' >
                    <Button
                        color="transparent"
                        className="btn-acto"
                        ripple="dark"
                    >
                        Información de producto
                    </Button>
                </div>
                <div className='pt-4' >
                    <Button
                        color="transparent"
                        className="btn-acto"
                        ripple="dark"
                    >
                        Terminos y condiciones
                    </Button>
                </div>
                <div className='pt-4'>
                    <a
                        href="/aviso-de-privacidad"
                        target="_top"
                        rel="noreferrer"
                    >
                        <Button
                            color="transparent"
                            className="btn-acto"
                            ripple="dark"
                        >
                            Aviso de privacidad
                        </Button>
                    </a>
                </div>
            </div>
            <div className="lg:hidden text-center">
                <div className='text-acto text-lg font-bold' >¿Quieres saber más?</div>
                <div className='text-gray-700 pt-6' >Estos documentos contienen todo lo que necesita saber:</div>
                <div className='pt-6' >
                    <Button
                        color="transparent"
                        className="btn-acto mx-auto"
                        ripple="dark"
                    >
                        Condiciones de consumidor
                    </Button>
                </div>
                <div className='pt-4' >
                    <Button
                        color="transparent"
                        className="btn-acto mx-auto"
                        ripple="dark"
                    >
                        Información de producto
                    </Button>
                </div>
                <div className='pt-4' >
                    <Button
                        color="transparent"
                        className="btn-acto mx-auto"
                        ripple="dark"
                    >
                        Terminos y condiciones
                    </Button>
                </div>
                <div className='pt-4'>
                    <a
                        href="/aviso-de-privacidad"
                        target="_top"
                        rel="noreferrer"
                    >
                        <Button
                            color="transparent"
                            className="btn-acto mx-auto"
                            ripple="dark"
                        >
                            Aviso de privacidad
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    )
}