
export default function Header() {

    const data = [
        {
            header: `Aviso de Privacidad (Tradicional)`,
            body: `Para una insurtech como Gracias ACTO S.A.P.I. DE CV, con domicilio en Av. Rubén Darío 1109, Prados Providencia, 44630, GDL, Jalisco (“ACTO”) el tratamiento legítimo, controlado e informado de sus datos personales es de vital importancia para alcanzar los objetivos corporativos a través de todas las áreas del negocio y reiterar nuestro compromiso con la privacidad y su derecho a la autodeterminación informativa. El presente Aviso de Privacidad tiene como objeto informarle sobre el tratamiento que se le dará a sus datos personales cuando los mismos son recabados, utilizados, almacenados y/o transferidos por ACTO, lo cual se lleva a cabo de conformidad con las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.`,
            list: [
                ``
            ],
            footer: ``
        },
        {
            header: `Datos Personales o información personal`,
            body: `ACTO podrá recabar u obtener sus datos de forma directa por asistencia a cualquiera de nuestras oficinas, a través de solicitudes o formatos proporcionados por medios físicos, electrónicos, video llamadas, telefonía, correo, página web o aplicaciones móviles (apps) o de forma indirecta, a través de transferencias que realicen personas autorizadas físicas o morales, que se hayan obtenido con anterioridad con motivo de la celebración o de la posible celebración de un acto que implique la existencia de una relación jurídica. En este caso, quienes transfieran a ACTO deberán contar con la previa autorización del titular de los datos para el tratamiento de los mismos. Los datos personales sometidos a tratamiento son los siguientes: Datos de identificación como: nombre, domicilio, teléfono, correo electrónico, CURP, fecha de nacimiento, edad, nacionalidad, país de residencia, país de origen, documentación migratoria, número de seguridad social, número de pasaporte, carta del servicio militar, forma de contacto preferida, dirección de protocolo de Internet o número único de identificación de su dispositivo y localización, fotografía, imágenes captadas por sistemas de vigilancia en caso de acudir a nuestras oficinas en cualquier sede, procedimientos administrativos o judiciales de los que sea o haya sido parte.`,
            list: [
                `Datos laborales como: Puesto, domicilio laboral, correo electrónico, teléfono y fax del trabajo, cartas de recomendación, contrato corporativo y nombre del empleador.`,
                `Datos de educación como: Trayectoria educativa, título, número de cédula profesional, especialidad, tipos de práctica y certificados de estudios.`,
                `Datos de salud como: Estado de salud, historial clínico, enfermedades o padecimientos.`,
                `Datos bancarios y de facturación como: Número de cuenta, CLABE, RFC, datos de facturación, estados de cuenta como AFORE, INFONAVIT y FONACOT.`,
                `Datos personales sensibles son aquellos datos que afectan la esfera más íntima del titular o cuya utilización indebida puede dar origen a discriminación o conlleve un grave riesgo para el titular. Se consideran sensibles aquellos datos que puedan revelar aspectos como: origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas y preferencia sexual.`
            ],
            footer: `Los datos personales y los datos personales sensibles que se recaben o se generen para los fines de la relación jurídica que se celebre o llegare a celebrar serán utilizados únicamente para el cumplimiento de dicha relación jurídica con estricto apego a lo establecido en este Aviso de Privacidad.`
        },
        {
            header: `Datos personales de menores de edad`,
            body: `En ACTO conocemos la importancia que tiene la protección de la privacidad y los datos personales de los menores de edad, por lo que ACTO no obtiene, usa, divulga o almacena información relacionada con menores de edad, sin el consentimiento previo de sus padres o tutores. Si usted es padre, madre o tutor de algún menor de edad que haya proporcionado sus datos personales sin su consentimiento, puede solicitar que dichos datos sean cancelados contactando via correo a la dirección <>ventas</> y al teléfono 33114455760`
        },
        {
            header: `Finalidades y/o Usos de los datos personales`,
            body: `La información del usuario, compartida por este medio, redes sociales, teléfono y visitas a nuestras oficinas seán utilizados para:`,
            list: [
                `Cotizar y emitir pólizas de seguro a través de la cedula de ACTO, AGENTE DE SEGUROS Y DE FIANZAS S.A. DE C.V.`,
                `Cotizar y emitir pólizas de asistencia`,
                `Brindar un servicio personalizado de comunicación y seguimiento a los procesos que incluye envió de promociones, programas de lealtad`,
                `Realizar campañas de marketing y mailing en medios digitales que incluyen envío de promociones, envío de información pertinente de uso, programas de lealtad y otra información pertinente para maximizar la relación entre ACTO y el usuario.`
            ],
            footer: `Sus datos personales serán tratados únicamente por el tiempo necesario a fin de cumplir con las finalidades descritas en este Aviso de Privacidad o en aquel que se ponga a su disposición. En caso de negativa al tratamiento de sus datos personales con finalidades distintas a las mencionadas, contacte via email al correo <>ventas</> o marque al número (33) 3246-5397 y solicite que se elimine y destruya toda información compartida con ACTO.`
        },
        {
            header: `Otra información no personal que se puede recabar`,
            body: `Recibimos y almacenamos cierta información cuando usted interactúa en nuestros sitios web o utiliza alguna aplicación administrada por ACTO, por ejemplo: información técnica como su dirección de protocolo de Internet, su sistema operativo y su tipo de navegador, la dirección de un sitio web de referencia y la ruta que usted sigue durante su recorrido por nuestros sitios web. Esta información se recaba a fin de permitir que los sitios web operen correctamente, evaluar el uso de los sitios web y mostrar información que le pudiera resultar de interés. Asimismo, se pueden utilizar “cookies”, a fin de brindarle una mejor experiencia de navegación cuando utilice o regrese a nuestros sitios web. Una “cookie” es un archivo de texto que un sitio web o un correo electrónico puede salvar en su navegador y almacenarlo en su disco duro. Usted puede elegir navegar en nuestros sitios web sin aceptar las cookies. Sin cookies habilitadas, usted podrá navegar en nuestros sitios web, pero es posible que no pueda utilizar algunas aplicaciones o características de los sitios.

            Es posible deshabilitar “cookies” en todo tipo de navegadores; sin embargo, para ejemplificar, se señalan a continuación los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al menú “Herramientas”; dar clic en “borrar datos de navegación”, seleccionar la opción “eliminar cookies y otros datos de sitios y de complementos”, dar clic en “aceptar”.`
        },
        {
            header: `Enlaces desde nuestros sitios web`,
            body: `Nuestros sitios web pueden contener, para su conveniencia, enlaces a otros sitios web que no pertenecen a ACTO. ACTO no ha revisado las Políticas de Privacidad ni Avisos de Privacidad de dichos sitios web, por lo que no garantiza ni se hace responsable por el contenido en dichos enlaces ni el tratamiento de datos personales que lleven a cabo. Lo exhortamos a que lea cuidadosamente la Política y Avisos de Privacidad de cada uno de los sitios que pudieran estar vinculados desde nuestros sitios web.`
        },
        {
            header: `Medidas de Seguridad`,
            body: `ACTO ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.`
        },
        {
            header: `Transferencias de datos personales`,
            body: `ACTO únicamente transferirá sus datos personales a terceros, nacionales o extranjeros, que estén relacionados jurídica o comercialmente con ACTO para cumplir con las finalidades descritas en el o los Avisos de Privacidad puestos a su disposición.

            Asimismo, ACTO podrá transferir sus datos personales en los casos previstos y autorizados por la Ley. Cuando ACTO venda una unidad comercial a otra compañía y sus datos personales sean usados por dicha unidad comercial, los mismos podrán transferirse al comprador junto con el negocio a fin de que éste pueda utilizarlos de la misma manera en que ACTO los utilizaba.
            
            En el caso de realizar una transferencia de datos del titular de los mismos en la cual se requiera su consentimiento expreso ACTO se lo informará para obtener dicha autorización.`
        },
        {
            header: `Derechos del titular de los datos personales`,
            body:`El titular de datos personales puede ejercitar los derechos de acceso, rectificación, cancelación y oposición (derechos “ARCO”) respecto a sus datos personales. Asimismo, dicho titular puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de sus datos personales, siempre y cuando el tratamiento no sea un tratamiento necesario o que resulte de una relación jurídica.`
        },
        {
            header: ``,
            body:`Los derechos ARCO podrán ejercerlos directamente el titular o quien sus derechos represente, previa acreditación de su personalidad, y consisten en:`,
            list: [
                `Acceso.- Que se le informe cuáles de sus datos personales están contenidos en las bases de datos de ACTO, para qué se utilizan, el origen y las comunicaciones que se hayan realizado con los mismos y, en general, las condiciones y generalidades del tratamiento.`
            ]
        },
        {
            header: ``,
            body:`La obligación de acceso se dará por cumplida cuando:`,

        },
    ]

    const body = `Aviso de Privacidad (Tradicional)
    Para una insurtech como Gracias ACTO S.A.P.I. DE CV, con domicilio en Av. Rubén Darío 1109, Prados Providencia, 44630, GDL, Jalisco (“ACTO”) el tratamiento legítimo, controlado e informado de sus datos personales es de vital importancia para alcanzar los objetivos corporativos a través de todas las áreas del negocio y reiterar nuestro compromiso con la privacidad y su derecho a la autodeterminación informativa. El presente Aviso de Privacidad tiene como objeto informarle sobre el tratamiento que se le dará a sus datos personales cuando los mismos son recabados, utilizados, almacenados y/o transferidos por ACTO, lo cual se lleva a cabo de conformidad con las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
    Datos Personales o información personal
    ACTO podrá recabar u obtener sus datos de forma directa por asistencia a cualquiera de nuestras oficinas, a través de solicitudes o formatos proporcionados por medios físicos, electrónicos, video llamadas, telefonía, correo, página web o aplicaciones móviles (apps) o de forma indirecta, a través de transferencias que realicen personas autorizadas físicas o morales, que se hayan obtenido con anterioridad con motivo de la celebración o de la posible celebración de un acto que implique la existencia de una relación jurídica. En este caso, quienes transfieran a ACTO deberán contar con la previa autorización del titular de los datos para el tratamiento de los mismos. Los datos personales sometidos a tratamiento son los siguientes: Datos de identificación como: nombre, domicilio, teléfono, correo electrónico, CURP, fecha de nacimiento, edad, nacionalidad, país de residencia, país de origen, documentación migratoria, número de seguridad social, número de pasaporte, carta del servicio militar, forma de contacto preferida, dirección de protocolo de Internet o número único de identificación de su dispositivo y localización, fotografía, imágenes captadas por sistemas de vigilancia en caso de acudir a nuestras oficinas en cualquier sede, procedimientos administrativos o judiciales de los que sea o haya sido parte.
        •	Datos laborales como: Puesto, domicilio laboral, correo electrónico, teléfono y fax del trabajo, cartas de recomendación, contrato corporativo y nombre del empleador.
        •	Datos de educación como: Trayectoria educativa, título, número de cédula profesional, especialidad, tipos de práctica y certificados de estudios.
        •	Datos de salud como: Estado de salud, historial clínico, enfermedades o padecimientos.
        •	Datos bancarios y de facturación como: Número de cuenta, CLABE, RFC, datos de facturación, estados de cuenta como AFORE, INFONAVIT y FONACOT.
        •	Datos personales sensibles son aquellos datos que afectan la esfera más íntima del titular o cuya utilización indebida puede dar origen a discriminación o conlleve un grave riesgo para el titular. Se consideran sensibles aquellos datos que puedan revelar aspectos como: origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas y preferencia sexual.
    Los datos personales y los datos personales sensibles que se recaben o se generen para los fines de la relación jurídica que se celebre o llegare a celebrar serán utilizados únicamente para el cumplimiento de dicha relación jurídica con estricto apego a lo establecido en este Aviso de Privacidad.
    Datos personales de menores de edad
    En ACTO conocemos la importancia que tiene la protección de la privacidad y los datos personales de los menores de edad, por lo que ACTO no obtiene, usa, divulga o almacena información relacionada con menores de edad, sin el consentimiento previo de sus padres o tutores. Si usted es padre, madre o tutor de algún menor de edad que haya proporcionado sus datos personales sin su consentimiento, puede solicitar que dichos datos sean cancelados contactando via correo a la dirección ventas@grupoacto.com y al teléfono 33114455760
    Finalidades y/o Usos de los datos personales

    La información del usuario, compartida por este medio, redes sociales, teléfono y visitas a nuestras oficinas seán utilizados para:
        •	Cotizar y emitir pólizas de seguro a través de la cedula de ACTO, AGENTE DE SEGUROS Y DE FIANZAS S.A. DE C.V.
        •	Cotizar y emitir pólizas de asistencia
        •	Brindar un servicio personalizado de comunicación y seguimiento a los procesos
        •	que incluye envió de promociones, programas de lealtad
        •	Realizar campañas de marketing y mailing en medios digitales que incluyen envío de promociones, envío de información pertinente de uso, programas de lealtad y otra información pertinente para maximizar la relación entre ACTO y el usuario. 
    Sus datos personales serán tratados únicamente por el tiempo necesario a fin de cumplir con las finalidades descritas en este Aviso de Privacidad o en aquel que se ponga a su disposición. En caso de negativa al tratamiento de sus datos personales con finalidades distintas a las mencionadas, contacte via email al correo ventas@grupoacto.com o marque al número (33) 3246-5397 y solicite que se elimine y destruya toda información compartida con ACTO.
    Otra información no personal que se puede recabar
    Recibimos y almacenamos cierta información cuando usted interactúa en nuestros sitios web o utiliza alguna aplicación administrada por ACTO, por ejemplo: información técnica como su dirección de protocolo de Internet, su sistema operativo y su tipo de navegador, la dirección de un sitio web de referencia y la ruta que usted sigue durante su recorrido por nuestros sitios web. Esta información se recaba a fin de permitir que los sitios web operen correctamente, evaluar el uso de los sitios web y mostrar información que le pudiera resultar de interés. Asimismo, se pueden utilizar “cookies”, a fin de brindarle una mejor experiencia de navegación cuando utilice o regrese a nuestros sitios web. Una “cookie” es un archivo de texto que un sitio web o un correo electrónico puede salvar en su navegador y almacenarlo en su disco duro. Usted puede elegir navegar en nuestros sitios web sin aceptar las cookies. Sin cookies habilitadas, usted podrá navegar en nuestros sitios web, pero es posible que no pueda utilizar algunas aplicaciones o características de los sitios.
    Es posible deshabilitar “cookies” en todo tipo de navegadores; sin embargo, para ejemplificar, se señalan a continuación los pasos que hay que seguir para deshabilitarlas en Google Chrome: Ir al menú “Herramientas”; dar clic en “borrar datos de navegación”, seleccionar la opción “eliminar cookies y otros datos de sitios y de complementos”, dar clic en “aceptar”.
    Enlaces desde nuestros sitios web
    Nuestros sitios web pueden contener, para su conveniencia, enlaces a otros sitios web que no pertenecen a ACTO. ACTO no ha revisado las Políticas de Privacidad ni Avisos de Privacidad de dichos sitios web, por lo que no garantiza ni se hace responsable por el contenido en dichos enlaces ni el tratamiento de datos personales que lleven a cabo. Lo exhortamos a que lea cuidadosamente la Política y Avisos de Privacidad de cada uno de los sitios que pudieran estar vinculados desde nuestros sitios web.
    Medidas de Seguridad
    ACTO ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.
    Transferencias de datos personales
    ACTO únicamente transferirá sus datos personales a terceros, nacionales o extranjeros, que estén relacionados jurídica o comercialmente con ACTO para cumplir con las finalidades descritas en el o los Avisos de Privacidad puestos a su disposición.
    Asimismo, ACTO podrá transferir sus datos personales en los casos previstos y autorizados por la Ley. Cuando ACTO venda una unidad comercial a otra compañía y sus datos personales sean usados por dicha unidad comercial, los mismos podrán transferirse al comprador junto con el negocio a fin de que éste pueda utilizarlos de la misma manera en que ACTO los utilizaba.
    En el caso de realizar una transferencia de datos del titular de los mismos en la cual se requiera su consentimiento expreso ACTO se lo informará para obtener dicha autorización.
    Derechos del titular de los datos personales
    El titular de datos personales puede ejercitar los derechos de acceso, rectificación, cancelación y oposición (derechos “ARCO”) respecto a sus datos personales. Asimismo, dicho titular puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de sus datos personales, siempre y cuando el tratamiento no sea un tratamiento necesario o que resulte de una relación jurídica.
    Los derechos ARCO podrán ejercerlos directamente el titular o quien sus derechos represente, previa acreditación de su personalidad, y consisten en:
        •	Acceso.- Que se le informe cuáles de sus datos personales están contenidos en las bases de datos de ACTO, para qué se utilizan, el origen y las comunicaciones que se hayan realizado con los mismos y, en general, las condiciones y generalidades del tratamiento.
    La obligación de acceso se dará por cumplida cuando:
        •	Los datos se pongan a disposición del titular de los datos personales, o bien,
        •	Mediante la expedición de copias simples, medios magnéticos, ópticos, sonoros, visuales, holográficos, documentos electrónicos o cualquier otro medio o tecnología que se considere adecuada.
        •	Rectificación.- Que se corrijan o actualicen sus datos personales en caso de que sean inexactos o incompletos y acompañar documentos que sustenten el cambio.
        •	Cancelación.- que sus datos personales sean dados de baja, total o parcialmente, de las bases de datos de ACTO. Esta solicitud podrá dar lugar a un periodo de bloqueo tras el cual procederá la supresión de los datos. No obstante, ACTO podrá abstenerse de cancelar los datos personales, de conformidad con lo dispuesto por al artículo 26 de la Ley.
        •	Oposición.- Oponerse por causa legítima al tratamiento de sus datos personales por parte de ACTO, salvo cuando el tratamiento sea necesario para el cumplimiento de una obligación contraída con ACTO.
    El ejercicio de los derechos previstos en la Ley se podrá llevar a cabo a través del envío de su solicitud debidamente firmada y acompañada de su identificación oficial o instrumento notarial que lo acredite como titular de los datos personales o a través de su Representante o Apoderado legal en los términos establecidos por la Ley y su Reglamento, al Departamento de Datos Personales de ACTO. El mecanismo de contacto es a través de via electrónica enviando correo a ventas@grupoacto.com o por teléfono (33) 11455760 
    Modificaciones al Aviso de Privacidad
    ACTO se reserva el derecho de enmendar o modificar el presente Aviso de Privacidad o el que haya puesto a su disposición, como y cuando estime conveniente, por ejemplo, para cumplir con cambios a la legislación o cumplir con disposiciones internas de ACTO. ACTO pondrá a su disposición los Avisos de Privacidad actualizados en el sitio web www.grupoacto.com o le hará llegar un comunicado al correo electrónico que nos haya proporcionado.
    Fecha de la última actualización: Mayo 2022
    
    `
    const fontBold = (data) => {
        let otherText = data.split("<b>")
        let endText
        let html = []
        otherText.map((t, i) => {
            endText = t.split("</b>")
            if(endText.length > 1 ){
                html.push(<><b>{endText[0]}</b>{endText[1]}</>)
            } else {
                html.push(<>{t}</>)
            }
            return true
        })
        return html
    }

    const getFormat = (data) => {
        let text
        let html = []
        return data.split(String.fromCharCode(10)).map((data, index) => {
          text = data.split(String.fromCharCode(9))
          html = []
          if(text.length > 1) {
            if (data.includes("<b>")){
                html.push(fontBold(data))
                return <div className='p-1' >{
                        html.map((v,i) => {
                        return <span key={i} >{v}</span>
                        })
                    }</div>
            } else {
              return <div className='p-1'>{data}</div>
            }
          } else {
            text = data.split('.')
            if (text.length === 1) {
                if (text[0].trim() !== '') {
                    return <div className='font-bold p-1'>{data}</div>
                }
                return <></>;
            } else {
                if (data.includes("<b>")){
                    html.push(fontBold(data))
                    return <div className='p-1' >{
                    html.map((v,i) => {
                        return <span key={i} >{v}</span>
                    })
                    }</div>
                } else {
                    return <div className='p-1'>{data}</div>
                }
            }
          }
        })
      }

    return (
        <div className="relative pt-20 flex content-center items-center justify-center bg-gray-200">
            <div className="container pt-20 pb-20 max-w-7xl px-4 relative mx-auto">
                <div className="w-full mx-auto rounded-2xl bg-white p-10" >
                    <div className="text-acto text-4xl uppercase font-bold text-center" style={{}}>Aviso de privacidad</div>
                    <div className="mt-8" >{getFormat(body)}</div>
                </div>
            </div>
        </div>
    )
}