import FslightboxReact from "fslightbox-react";
import { useState } from "react";
import BgVideoMobile from 'assets/img/mobile/bg-video-play.png'
import BgVideo from 'assets/img/bg-video.png'

const { innerWidth: width} = window;

const Mobile = () => {
    return width <= 432 || false;
}

export default function WorkingSection() {
    const [index, setIndex] = useState(0)
    const [show, setShow] = useState(false)
    const showVideo = (index) => {
        setIndex(index)
        setShow(!show)
    }
    const videos = [
        ["https://www.youtube.com/watch?v=nnkQgeus4hs"]
    ]
    
    return (
        <section className="bg-blue-acto flex items-center justify-center">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="bg-video mx-auto cursor-pointer" >
                    {
                        Mobile()?
                        <img src={BgVideoMobile} alt='youtube-video' className="mx-auto" width={262} onClick={() => showVideo(0)}/>
                        : <img src={BgVideo} alt='youtube-video' className="mx-auto" onClick={() => showVideo(0)}/>    
                    }
                </div>
            </div>
            <FslightboxReact 
                toggler={show}
                sources={videos[index]}
            />
        </section>
    );
}
