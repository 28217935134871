import form from 'assets/img/need/11.png'
import phone from 'assets/img/need/14.png'
import ambulance from 'assets/img/need/15.png'
import { ReactComponent as Form } from 'assets/img/need/choice.svg';
import { ReactComponent as Phone } from 'assets/img/need/touch.svg';
import { ReactComponent as Assitance } from 'assets/img/need/assistance.svg';

export default function AssistanceSection() {
  return (
    <>
    <section className='bg-assistance md-hidden'>
          <div className='container max-w-7xl mx-auto px-4'>
            <div className='text-acto text-center text-6xl font-bold' >
                ¿Cómo funciona?
            </div>
              <div className='text-blue mx-auto text-center text-40 mt-16 mb-19' >
                Solicita una asistencia en 3 pasos
              </div>
              <div className='flex flex-wrap justify-center text-lg mx-auto text-center text-2xl text-gray-600' style={{width: '90%'}}>
                <div className='sm:w-4/12'>
                  <div className='pr-4' >
                    <img src={phone} alt='phone' />
                  </div>
                  <div className='py-8'>
                    1. Elige la <span className='text-blue font-bold' >asistencia</span><br/> que necesitas
                  </div>
                </div>
                <div className='px-4 sm:w-4/12' >
                    <div>
                        <img src={form} alt='form' />
                    </div>
                    <div className='py-8'>
                        2. Solicita tu<span className='text-blue font-bold' >asistencia con el botón de llamada</span>
                    </div>
                </div>
                <div className='sm:w-4/12' >
                  <div className='px-4'> 
                    <img src={ambulance} alt='ambulance' />
                  </div>
                  <div className='py-8'>
                    3. Tu asistencia <span className='text-blue font-bold' >está en camino.</span> En caso de ser necesario nos comunicamos contigo por teléfono
                  </div>
                </div>
              </div>
          </div>
    </section>
    <section className='lg:hidden pb-8' style={{color: '#58595B'}}>
        <div className='container max-w-6xl mx-auto px-4'>
            <div className='text-acto text-center text-3xl font-bold' >
                ¿Cómo funciona?
            </div>
            <div className='text-blue mx-auto text-center pt-6 pb-8 text-xl' >
                Solicita una asistencia en 3 pasos
            </div>
            <div className='flex flex-wrap justify-center text-2xl text-center'>
                <div className='w-full' >
                    <div>
                      <Phone className='mx-auto' style={{width:'100%'}} />
                    </div>
                    <div className='py-4'>
                      1. Elige la <span className='text-blue' >asistencia</span><br/> que necesitas
                    </div>
                </div>
                <div className='w-full' >
                    <div>
                        <Form className='mx-auto' style={{width:'100%'}} />
                    </div>
                    <div className='py-4'>
                        2. Solicita tu<span className='text-blue' > asistencia con</span><br/>
                        <span className='text-blue' >el botón de llamada</span>
                    </div>
                </div>
                <div className='w-full' >
                  <div>
                    <Assitance className='mx-auto' />
                  </div>
                  <div className='py-4'>
                    3. Tu asistencia<span className='text-blue' > está en</span><br/>
                    <span className='text-blue' >camino.</span> En caso de ser<br/>
                    necesario nos<br/>
                    comunicaremos contigo por<br/>
                    teléfono.
                  </div>
                </div>
            </div>
          </div>
      </section>
    </>
  );
}
