import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import DownloadSection from 'components/us/Download';
import Header from 'components/us/Header';
import Mission from 'components/us/Mission';
import Born from 'components/us/Born';

export default function US() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <Mission />
                <Born />
                <DownloadSection />
            </main>
            <DefaultFooter />
        </>
    );
}
