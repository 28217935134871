import { Button, Input } from '@material-tailwind/react';

export default function NewsSection() {
    return (
        <section className="pb-20 relative block bg-white">
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24 md-hidden">
              <div className="flex flex-wrap">
                <div className="w-full mb-24 text-center" >
                  <div className='text-acto font-bold text-60 pb-10'>
                    Recibe noticias e información
                  </div>
                  <div className='text-blue text-4xl' >
                    Inscríbete a nuestra lista de envío<br/>
                    para recibir más información sobre<br/>
                    nuestros productos y servicios
                  </div>
                </div>
              </div>
                <div className="flex flex-wrap justify-center">
                  <div className="w-full md:w-10/12 mb-12" >
                    <div className='bg-blue-light bg-form-footer rounded-lg' >
                      <div className="flex flex-wrap flex-col items-center justify-center p-10">
                        <div className="flex gap-8 mt-16 mb-12">
                          <Input
                              type="text"
                              placeholder="Nombre"
                              color="blue"
                              className="text-xl"
                          />
                          <Input
                              type="email"
                              placeholder="Email"
                              color="lightBlue"
                              className="text-xl"
                          />
                          <Input
                              type="phone"
                              placeholder="Telefono"
                              color="lightBlue"
                              className="text-xl"
                          />
                      </div>
                        <div class="py-4">
                          <div class="flex items-center">
                            <input id="remember" type="checkbox" class="mt-checkbox mt-checkbox-light-blue-500 hidden overflow-hidden" />
                            <label for="remember" class="flex items-center cursor-pointer text-blue select-none transition-all duration-300 text-sm">
                              <span class="relative w-5 h-5 inline-block mr-2 rounded border border-gray-500 transition-all duration-300"></span>
                              Quiero recibir noticias sobre productos y servicios de ACTO
                            </label>
                          </div>
                        </div>
                        <div >
                          <Button
                            color="transparent"
                            className="bg-color-acto-vertical text-white text-normal text-xl mx-auto rounded-6xl px-9 py-7 w-full"
                            ripple="dark"
                          >
                            Inscríbete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="container max-w-7xl mx-auto px-4 lg:hidden">
              <div className="flex flex-wrap text-center">
                <div className="w-full pb-10" >
                  <div className='text-acto font-bold text-2xl pb-4'>
                    Recibe noticias e<br/>
                    información</div>
                  <div className='text-blue text-lg leading-none-forced' >
                    Inscríbete a nuestra lista de envío<br/>
                    para recibir más información sobre<br/>
                    nuestros productos y servicios
                  </div>
                </div>
                <div className="w-full md:w-5/12" ></div>
              </div>
                <div className="flex flex-wrap justify-center px-8" >
                  <div className="mb-12" >
                    <div className='bg-blue-light bg-form-footer rounded-lg mx-auto' style={{width: "80%"}} >
                      <div className="flex flex-wrap flex-col items-center justify-center px-8">
                        <div className="flex flex-wrap gap-8 mt-8 mb-6">
                          <Input
                              type="text"
                              placeholder="Nombre"
                              color="blue"
                              className="text-xl"
                          />
                          <Input
                              type="email"
                              placeholder="Email"
                              color="lightBlue"
                              className="text-xl"
                          />
                          <Input
                              type="phone"
                              placeholder="Telefono"
                              color="lightBlue"
                              className="text-xl"
                          />
                      </div>
                        <div class="pb-10">
                          <div class="flex items-center">
                            <input id="remember" type="checkbox" class="mt-checkbox mt-checkbox-light-blue-500 hidden overflow-hidden" />
                            <label for="remember" class="flex items-center cursor-pointer text-blue select-none transition-all duration-300 text-xs">
                              <span class="relative w-5 h-5 inline-block mr-2 rounded border border-gray-500 transition-all duration-300"></span>
                              Quiero recibir noticias sobre<br/>productos y servicios de ACTO
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-72'>
                    <Button
                      color="transparent"
                      className="bg-color-acto-vertical text-white text-normal text-xs rounded-2xl w-full"
                      ripple="dark"
                    >
                      Inscríbete
                    </Button>
                  </div>
                </div>
            </div>
        </section>
    );
}
