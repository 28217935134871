import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
//import Header from 'components/beneficios/Header';
import DownloadSection from 'components/beneficios/Download';
import SliderSection from 'components/beneficios/SliderMaster';
import Assistance from 'components/beneficios/Assistance';
import ProtectionSection from 'components/beneficios/Protections';

export default function Beneficios() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
              <SliderSection />
              <Assistance />
              <ProtectionSection />
              <DownloadSection />
            </main>
            <DefaultFooter />
        </>
    );
}
