import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/landing/Header';
import WorkingSection from 'components/landing/WorkingSection';
import TeamSection from 'components/landing/TeamSection';
import ContactSection from 'components/landing/ContactSection';
import Experience from 'components/landing/Experience';
import Protection from 'components/landing/Protection';
import Suscriptors from 'components/landing/Suscriptors';
import DownloadSection from 'components/landing/Download';
import NewsSection from 'components/landing/News';
import AssistanceSection from 'components/landing/Assistance';
import MomentSection from 'components/landing/Moment';
import WhatIs from 'components/landing/WhatIs';

export default function Landing() {

    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <Experience />
                <WorkingSection />
                <WhatIs />
                <AssistanceSection />
                <Protection />
                <MomentSection />
                <ContactSection />
                <TeamSection />
                <Suscriptors />
                <DownloadSection />
                <NewsSection />
            </main>
            <DefaultFooter />
        </>
    );
}
