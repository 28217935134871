import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Documents from './Documents';
import AccordionSubscriptions from './Lists/Subscriptions';
import AccordionExclusions from './Lists/Exclusions';
import AccordionObligations from './Lists/Obligations';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
		style={{width: '100%', marginTop: '-2rem' }}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
    },
    tabs: {
	  alignItems: 'center'
    },
  }));

export default function TabSection() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

	const Tab = withStyles({
		root: {
		  margin: '0.75rem 0',
		  color: '#007EFF',
		  fontSize: 18,
		  textTransform: 'none',
		  padding: 0,
		  lineHeight: 1.5,
		  opacity: 1,
		  borderRadius: 10,
		  width: '100%'
		},
		selected: {
			color: 'white',
			background: 'linear-gradient(90deg, rgba(69,16,138,1) 0%, rgba(69,16,138,1) 10%, rgba(124,29,201,1) 100%)',
		}
	})(MuiTab);

    return(
		<section>
			<div className='container max-w-7xl mx-auto md-hidden' >
				<div className='pb-8 text-3xl text-blue font-bold'>Temas de ayuda</div>
				<div className={classes.root} >
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs"
						className={classes.tabs}
						TabIndicatorProps={{ style: { backgroundColor: 'transparent' }}}
					>
						<Tab label="Suscripción" {...a11yProps(0)} />
						<Tab label="Suma asegurada y exclusiones" {...a11yProps(1)} />
						<Tab label="Obligaciones" {...a11yProps(2)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						<AccordionSubscriptions />
						<Documents />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<AccordionExclusions />
						<Documents />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<AccordionObligations />
						<Documents />
					</TabPanel>
				</div>
			</div>
			<div className='container max-w-7xl mx-auto lg:hidden' >
				<div className='pb-8 text-3xl text-blue font-bold text-center'>Temas de ayuda</div>
				<div>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs"
						className={classes.tabs}
						TabIndicatorProps={{ style: { backgroundColor: 'transparent' }}}
					>
						<Tab label="Suscripción" {...a11yProps(0)} />
						<Tab label="Suma asegurada y exclusiones" {...a11yProps(1)} />
						<Tab label="Obligaciones" {...a11yProps(2)} />
					</Tabs>
				</div>
				<div>
					<TabPanel value={value} index={0}>
						<AccordionSubscriptions />
						<Documents />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<AccordionExclusions />
						<Documents />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<AccordionObligations />
						<Documents />
					</TabPanel>
				</div>
			</div>
		</section>
    )
}
