import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { Link } from 'react-router-dom';
import Message from 'assets/img/ballon-acto.png'

export default function DefaultFooter() {
    const { innerWidth: width} = window;

    const sendTo = () => {
        return width <= 432 ? "/#statements-mobile" : "/#statements";  
    }
    return (
        <>
            <footer className="relative bg-color-acto-vertical pt-8 pb-6">
                <div className="container max-w-7xl mx-auto">
                    <div className="flex flex-wrap text-center lg:text-left pt-6">
                        <div className="w-full lg:w-2/12 px-4">
                            <div className="-mt-4 pb-12">
                            <Link to="/">
                                <Logo className='mx-auto' style={{width: "6.375rem"}}></Logo>
                            </Link>
                            </div>
                        </div>
                        <div className="w-full lg:w-7/12 mx-auto">
                            <div className="flex flex-wrap items-top text-white uppercase">
                                <div className="w-full lg:w-3/12 px-4 ml-auto md:mb-0 mb-8">
                                    <span className="block uppercase text-sm font-serif font-bold mb-2">
                                        Quienes somos
                                    </span>
                                    <ul className="list-unstyled text-xs">
                                        <li>
                                            <a
                                                className="hover:text-gray-900 block"
                                                target="_top"
                                                rel="noreferrer"
                                                href="/nosotros"
                                            >
                                                Nosotros
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://grupoacto.com/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Acto holding
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-3/12 px-4">
                                    <span className="block text-sm font-serif font-bold mb-2">
                                        Membresías
                                    </span>
                                    <ul className="list-unstyled text-xs">
                                        <li>
                                            <a
                                                href="/beneficios"
                                                target="_self"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Beneficios
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://compras.acto.com.mx/subscription.php"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Suscríbete
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="/preguntas-frecuentes"
                                                target="_top"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Preguntas frecuentes
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={sendTo()}
                                                target="_self"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Testimonios
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-3/12 px-4">
                                    <span className="block text-sm font-serif font-bold mb-2">
                                        Descarga APP
                                    </span>
                                    <ul className="list-unstyled text-xs">
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.acto.seguros"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Google Play
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://apps.apple.com/mx/app/acto-membres%C3%ADas/id1548406100"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                App Store
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-3/12 px-4">
                                    <span className="block text-sm font-serif font-bold mb-2">
                                        Contacto
                                    </span>
                                    <ul className="list-unstyled text-xs">
                                        <li>
                                            <a
                                                href={`https://wa.me/+5213314483372?text=${encodeURIComponent('¡Hola! Necesito ayuda')}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Soporte app
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="tel:5551693934"
                                                target="_top"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block"
                                            >
                                                Asistencia
                                            </a>
                                        </li>
                                        <li>
                                            <div className="mt-4 text-normal">
                                                <a
                                                    href="mailto:ventas@acto.io"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="hover:text-gray-900 block"
                                                >
                                                    ventas@acto.io
                                                </a>    
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 bl-white">
                            <div className='flex justify-center'>
                                <div className="text-white uppercase font-bold w-full text-xs">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a
                                                href="https://compras.acto.com.mx"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block pb-1"
                                            >
                                                Iniciar sesión
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="/aviso-de-privacidad"
                                                target="_top"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block pb-1"
                                            >
                                                Aviso de privacidad
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="/terminos-y-condiciones"
                                                target="_top"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block pb-1"
                                            >
                                                Términos y condiciones
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                target="_top"
                                                rel="noreferrer"
                                                className="hover:text-gray-900 block pb-1"
                                            >
                                                Información legal
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='md-hidden'>
                                    <a
                                        href={`https://wa.me/+5213314483372?text=${encodeURIComponent('¡Hola! Necesito ayuda')}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="hover:text-gray-900 block pb-1"
                                    >
                                        <img src={Message} alt="messageFooter" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-6 border-gray-300" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-white font-medium py-1">
                                <span>POWERED BY GRUPO ACTO</span><br/>
                                Copyright © {new Date().getFullYear()}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
