import { Switch, Route, Redirect } from 'react-router-dom';
import Landing from 'pages/Landing';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';
import 'assets/styles/custom.css';
import Beneficios from 'pages/Beneficios';
import Warn from 'pages/Warn';
import US from 'pages/US';
import FAQ from 'pages/FAQ';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import Company from 'pages/Company';
import OpenApp from 'components/OpenApp';
import Success from 'pages/Success';
import Termns from 'pages/Termns';
import BannerApp from 'components/BannerApp';
import { useEffect } from 'react';
import { useState } from 'react';
import { getMobileOperatingSystem } from 'components/Dimensions';

function App() {
    const THEME = createTheme({
        typography: {
            "fontFamily": `"Montserrat", sans-serif`
        }
     });
    const [openBanner, setOpenBanner] = useState(false);

    useEffect(() => {
        let statusBanner = localStorage.getItem('statusBanner');
        if(getMobileOperatingSystem() !== "other" && statusBanner === null) {
            setTimeout(() => setOpenBanner(true), 1000)
        }
    }, [])

    return (
        <MuiThemeProvider theme={THEME}>
            <BannerApp open={openBanner} />
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/beneficios" component={Beneficios} />
                <Route exact path="/aviso-de-privacidad" component={Warn} />
                <Route exact path="/company" component={Company} />
                <Route exact path="/nosotros" component={US} />
                <Route exact path="/preguntas-frecuentes" component={FAQ} />
                <Route exact path="/openapp" component={OpenApp} />
                <Route exact path="/payment" component={OpenApp} />
                <Route exact path="/success" component={Success} />
                <Route exact path="/terminos-y-condiciones" component={Termns} />
                <Redirect from="*" to="/" />
            </Switch>
        </MuiThemeProvider>
    );
}

export default App;
