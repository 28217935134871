import Button from '@material-tailwind/react/Button';
import { ReactComponent as LogoGray } from 'assets/img/acto-gray.svg';

export default function ContactSection() {
    return (
        <>
        <section className="bg-faro md-hidden">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6/12" >
                        <div className="flex flex-col text-left">
                            <div className="text-acto font-bold text-56 py-4">
                                Dinero en efectivo <br/>
                                cuando más lo<br/>
                                necesitas
                            </div>
                            <div className="text-gray-700 lg:text-2xl md:text-2xl pt-4 pb-10" >
                                <div className='flex flex-wrap items-center' >
                                    La membresía 
                                    <LogoGray className='mx-2' style={{width: 45, height: 20}} />
                                    te entrega
                                </div>
                                efectivo en caso de cualquier <br/>
                                accidente.
                            </div>
                            <div className="pt-8">
                                <a
                                    href="https://compras.acto.com.mx/subscription.php"
                                    target='_blank'
                                    rel="noreferrer"
                                >
                                <Button
                                    color="transparent"
                                    className="bg-color-acto text-white text-normal text-2xl px-9 py-7 rounded-4xl"
                                    ripple="dark"
                                    
                                >
                                    ¡Protégete Ya!
                                </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="pt-1 lg:hidden">
            <div className="container w-full bg-faro" />
            <div className="container max-w-7xl mx-auto px-4" style={{marginTop: '-4rem'}}>
                <div className="flex flex-wrap">
                    <div className="w-full" >
                        <div className="flex flex-col text-center">
                            <div className="text-acto font-bold text-2xl pb-6 leading-none">
                                Dinero en efectivo <br/>
                                cuando más lo<br/>
                                necesitas
                            </div>
                            <div className="text-gray-700 pb-2" >
                                <div className='flex flex-wrap items-center justify-center'>
                                    <div>La membresía</div>
                                    <div className='px-2'>
                                        <LogoGray style={{width: 36, height: 16}} />
                                    </div>
                                    <div>te entrega</div>
                                </div>
                                dinero en efectivo y te asiste<br/>
                                cuando más lo necesitas.
                            </div>
                            <div className="mx-auto pt-4">
                                <a
                                    href="/beneficios"
                                    target='_top'
                                    rel="noreferrer"
                                >
                                    <Button
                                        color="transparent"
                                        className="bg-color-acto text-white text-normal text-xl rounded-4xl btn-20"
                                        ripple="dark"
                                    >
                                        Conoce más beneficios
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
