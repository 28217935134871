import { ReactComponent as LogoGray } from 'assets/img/acto-gray.svg';
import { ReactComponent as LogoPink } from 'assets/img/acto-pink.svg';

export default function WhatIs() {
    return (
        <section>
            <div className='container max-w-7xl mx-auto px-4 text-center md-hidden' style={{marginBottom: '4rem'}}>
                <div className='text-acto text-6xl font-bold flex flex-wrap justify-center items-center' >
                    ¿Qué es <LogoPink className='mx-4 px-2' />?
                </div>
                <div className='text-2xl text-gray-700 mt-10' >
                    <div className='flex flex-wrap justify-center items-center' >
                        <LogoGray className='mx-2 mt-1' /> es una plataforma digital que ofrece servicios de
                    </div>
                    seguros médicos y de accidentes de una manera fácil y accesible.
                    <br/>
                    <br/>
                    <div className='flex flex-wrap justify-center items-center' >
                        En <LogoGray className='mx-2 mt-1' /> por tan solo <span className='text-acto mx-2' > $499 pesos al mes + iva </span> tienes acceso a doctores a
                    </div>
                    domicilio, ambulancia, grúa 24/7 y dinero en efectivo para cualquier tipo de accidente.
                </div>
            </div>
            <div className='container max-w-7xl mx-auto px-4 text-center lg:hidden' style={{marginBottom: '4rem'}}>
                <div className='text-acto text-4xl font-bold flex flex-wrap justify-center items-center' >
                    ¿Qué es <LogoPink style={{width: 92, height: 32}} className='mx-1' />?
                </div>
                <div className='text-gray-700 mt-10' >
                    <div className='flex flex-wrap justify-center items-center' >
                        <LogoGray className='logo-mobile-gray mx-2' /> es una plataforma digital que ofrece<br/>
                    </div>
                        servicios de<br/>
                        seguros médicos y de accidentes de una<br/>
                        manera fácil y accesible.
                    <br/>
                    <br/>
                    <div className='flex flex-wrap justify-center items-center' >
                        En <LogoGray className='logo-mobile-gray mx-2' /> por tan solo <span className='text-acto mx-2' > $499 pesos al mes +</span>
                    </div>
                    <div className='flex flex-wrap justify-center items-center' >
                        <span className='text-acto mx-2' >iva </span> tienes acceso a doctores a domicilio,
                    </div>
                    ambulancia, grúa 24/7 y dinero en efectivo <br/>
                    para cualquier tipo de accidente.
                </div>
            </div>
        </section>
    );
}
